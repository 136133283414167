import React from 'react';

const Portfolio  = () => {
  return (
    <>
    <main className="main__content_wrapper">
        
        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Portfolio Grid</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Portfolio</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}

        {/* <!-- Start portfolio section --> */}
        <section className="portfolio__section section--padding">
            <div className="container">
                <div className="section__heading text-center mb-40">
                    <span className="section__heading--subtitle">Our Portfolio</span>
                    <h2 className="section__heading--maintitle">Watch Our Portfolio</h2>
                </div>
                <div className="portfolio__section--inner">
                    <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-2 mb--n30">
                        <div className="col mb-30">
                            <div className="portfolio__items">
                                <div className="portfolio__items--thumbnail position__relative">
                                    <a className="portfolio__items--thumbnail__link display-block glightbox" data-gallery="portfolio" href="assets/img/other/portfolio1.png"><img className="portfolio__items--thumbnail__img display-block" src="assets/img/other/portfolio1.png" alt="portfolio-img" />
                                        <div className="portfolio__view--icon">
                                            <span className="portfolio__view--icon__link " ><svg xmlns="http://www.w3.org/2000/svg" width="39.697" height="27.066" viewBox="0 0 39.697 27.066">
                                                <path  d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z" transform="translate(-1 -4.5)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-30">
                            <div className="portfolio__items">
                                <div className="portfolio__items--thumbnail position__relative">
                                    <a className="portfolio__items--thumbnail__link display-block glightbox" data-gallery="portfolio" href="assets/img/other/portfolio2.png"><img className="portfolio__items--thumbnail__img display-block" src="assets/img/other/portfolio2.png" alt="portfolio-img" />
                                        <div className="portfolio__view--icon">
                                            <span className="portfolio__view--icon__link " ><svg xmlns="http://www.w3.org/2000/svg" width="39.697" height="27.066" viewBox="0 0 39.697 27.066">
                                                <path  d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z" transform="translate(-1 -4.5)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-30">
                            <div className="portfolio__items">
                                <div className="portfolio__items--thumbnail position__relative">
                                    <a className="portfolio__items--thumbnail__link display-block glightbox" data-gallery="portfolio" href="assets/img/other/portfolio3.png"><img className="portfolio__items--thumbnail__img display-block" src="assets/img/other/portfolio3.png" alt="portfolio-img" />
                                        <div className="portfolio__view--icon">
                                            <span className="portfolio__view--icon__link " ><svg xmlns="http://www.w3.org/2000/svg" width="39.697" height="27.066" viewBox="0 0 39.697 27.066">
                                                <path  d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z" transform="translate(-1 -4.5)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-30">
                            <div className="portfolio__items">
                                <div className="portfolio__items--thumbnail position__relative">
                                    <a className="portfolio__items--thumbnail__link display-block glightbox" data-gallery="portfolio" href="assets/img/other/portfolio4.png"><img className="portfolio__items--thumbnail__img display-block" src="assets/img/other/portfolio4.png" alt="portfolio-img" />
                                        <div className="portfolio__view--icon">
                                            <span className="portfolio__view--icon__link " ><svg xmlns="http://www.w3.org/2000/svg" width="39.697" height="27.066" viewBox="0 0 39.697 27.066">
                                                <path  d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z" transform="translate(-1 -4.5)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-30">
                            <div className="portfolio__items">
                                <div className="portfolio__items--thumbnail position__relative">
                                    <a className="portfolio__items--thumbnail__link display-block glightbox" data-gallery="portfolio" href="assets/img/other/portfolio5.png"><img className="portfolio__items--thumbnail__img display-block" src="assets/img/other/portfolio5.png" alt="portfolio-img" />
                                        <div className="portfolio__view--icon">
                                            <span className="portfolio__view--icon__link " ><svg xmlns="http://www.w3.org/2000/svg" width="39.697" height="27.066" viewBox="0 0 39.697 27.066">
                                                <path  d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z" transform="translate(-1 -4.5)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-30">
                            <div className="portfolio__items">
                                <div className="portfolio__items--thumbnail position__relative">
                                    <a className="portfolio__items--thumbnail__link display-block glightbox" data-gallery="portfolio" href="assets/img/other/portfolio1.png"><img className="portfolio__items--thumbnail__img display-block" src="assets/img/other/portfolio1.png" alt="portfolio-img" />
                                        <div className="portfolio__view--icon">
                                            <span className="portfolio__view--icon__link " ><svg xmlns="http://www.w3.org/2000/svg" width="39.697" height="27.066" viewBox="0 0 39.697 27.066">
                                                <path  d="M20.849,4.5A21.341,21.341,0,0,0,1,18.033a21.322,21.322,0,0,0,39.7,0A21.341,21.341,0,0,0,20.849,4.5Zm0,22.555a9.022,9.022,0,1,1,9.022-9.022A9.025,9.025,0,0,1,20.849,27.055Zm0-14.435a5.413,5.413,0,1,0,5.413,5.413A5.406,5.406,0,0,0,20.849,12.62Z" transform="translate(-1 -4.5)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End portfolio section --> */}

        {/* <!-- Start brand logo section --> */}
        <div className="brand__logo--section section--padding pt-0">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="brand__logo--section__inner d-flex justify-content-between align-items-center">
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo1.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo2.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo3.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo4.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo5.png" alt="brand img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End brand logo section --> */}

       {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}
    </main>    
    </>
);
};

export default Portfolio;