import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MyTicket = () => {
    
    
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            window.location.href = '/login';
        } else {
            const fetchOrders = async () => {
                try {
                    const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/support/view', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    // Ensure response.data is an array
                    if (Array.isArray(response.data.data)) {
                        setOrders(response.data.data); // Adjust based on the actual structure
                    } else {
                        console.error('Unexpected response structure:', response.data);
                        setOrders([]); // Set an empty array or handle accordingly
                    }
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching orders:', error);
                    setLoading(false);
                }
            };

            fetchOrders();
        }
    }, [navigate]);

   

    if (loading) {
        return <div>Loading...</div>;
    }

    

  

    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('auth_token');

        // Redirect to the login page
        window.location.href = '/login';
    };

  return (
    <>
    <main className="main__content_wrapper">

        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">My Ticket</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">My Ticket</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}
        
        {/* <!-- my account section start --> */}
        <section className="my__account--section section--padding">
            <div className="container text-left">
                <p className="account__welcome--text">Hello, welcome to your dashboard!</p>
                <div className="my__account--section__inner border-radius-10 d-flex">
                    <div className="account__left--sidebar">
                        <h2 className="account__content--title h3 mb-20">My Profile</h2>
                        <ul className="account__menu">
                            <li className="account__menu--list"><a href="/my-account">Dashboard</a></li>
                            {/* <li className="account__menu--list"><a href="/my-account-2">Address</a></li> */}
                            <li className="account__menu--list"><a href="/wishlist">Wishlist</a></li>
                            <li className="account__menu--list active"><a href="/my-ticket">My Tickets</a></li>
                            <li className="account__menu--list">
                <a onClick={handleLogout}>Log Out</a>
            </li>
                        </ul>
                    </div>
                    <div className="account__wrapper">
                        <div className="account__content">
                            <h2 className="account__content--title h3 mb-20">My Tickets</h2>
                            <div className="account__table--area">
                                <table className="account__table">
                                    <thead className="account__table--header">
                                        <tr className="account__table--header__child">
                                            <th className="account__table--header__child--items">Order Id</th>
                                            <th className="account__table--header__child--items">Date</th>
                                            <th className="account__table--header__child--items">Subject</th>
                                            <th className="account__table--header__child--items">Message</th>
                                            <th className="account__table--header__child--items">Status</th>
                                          	 	 	
                                        </tr>
                                    </thead>
                                    <tbody className="account__table--body mobile__none">
                                    {orders.map(order => (
                    <tr className="account__table--body__child" key={order.id}>
                        <td className="account__table--body__child--items">{order.order_id}</td>
                        <td className="account__table--body__child--items">{new Date(order.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                        <td className="account__table--body__child--items">{order.subject}</td>
                        <td className="account__table--body__child--items">{order.message}</td>
                        <td className="account__table--body__child--items">
                {order.status}
            </td>
                    </tr>
                ))}
                                    </tbody>
                                    <tbody className="account__table--body mobile__block">
                                    {orders.map(order => (
                                        <tr className="account__table--body__child">
                                            <td className="account__table--body__child--items">
                                                <strong>Order Id</strong>
                                                <span>{order.order_id}</span>
                                            </td>
                                            <td className="account__table--body__child--items">
                                                <strong>Date</strong>
                                                <span>{new Date(order.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                            </td>
                                            <td className="account__table--body__child--items">
                                                <strong>Subject</strong>
                                                <span>{order.subject}</span>
                                            </td>
                                            <td className="account__table--body__child--items">
                                                <strong>Message</strong>
                                                <span>{order.message}</span>
                                            </td>
                                            <td className="account__table--body__child--items">
                                                <strong>Status</strong>
                                                <span>{order.status}</span>
                                            </td>
                                        </tr>
                                     ))} 
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- my account section end --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex ">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex ">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex ">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}

    </main>
    </>
);
};

export default MyTicket;

