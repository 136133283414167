import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Checkout3 = () => {


    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(null); // State to store order details
    const [error, setError] = useState(null); // State to store error messages
  

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            // Redirect to login if token is not present
            window.location.href = '/login';
        } else {
            // Simulate an API call or token validation if needed
            setLoading(false);
        }

        //fetch order details 
        const fetchLatestOrder = async () => {
            const token = localStorage.getItem('auth_token');
            try {
              const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/order/latest', {
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              });
      
              // Assuming the response data contains order details
              setOrderDetails(response.data);
            } catch (err) {
              console.error('Error fetching the latest order:', err);
              setError('Could not fetch the latest order details. Please try again.');
            }
          };
      
          fetchLatestOrder();
    }, [navigate]);

    if (error) {
        return <p>{error}</p>; // Display error message if there's an error
      }
    
      if (!orderDetails) {
        return <p>Loading order details...</p>; // Show loading message while fetching data
      }
  
  return (
    <>  
    {/* <!-- Start checkout page area --> */}
    <div className="checkout__page--area">
        <div className="container">
            <div className="checkout__page--inner d-flex text-left">
                <div className="main checkout__mian">
                    <header className="main__header checkout__mian--header mb-30">
                        {/* <a className="logo logo__left" href="/"><img src="assets/img/logo/nav-log.png" alt="logo" /></a> */}
                        <details className="order__summary--mobile__version">
                            <summary className="order__summary--toggle border-radius-5">
                                <span className="order__summary--toggle__inner">
                                    <span className="order__summary--toggle__icon">
                                        <svg width="20" height="19" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.178 13.088H5.453c-.454 0-.91-.364-.91-.818L3.727 1.818H0V0h4.544c.455 0 .91.364.91.818l.09 1.272h13.45c.274 0 .547.09.73.364.18.182.27.454.18.727l-1.817 9.18c-.09.455-.455.728-.91.728zM6.27 11.27h10.09l1.454-7.362H5.634l.637 7.362zm.092 7.715c1.004 0 1.818-.813 1.818-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817zm9.18 0c1.004 0 1.817-.813 1.817-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span className="order__summary--toggle__text show">
                                        <span>Show order summary</span>
                                        <svg width="11" height="6" xmlns="http://www.w3.org/2000/svg" className="order-summary-toggle__dropdown" fill="currentColor"><path d="M.504 1.813l4.358 3.845.496.438.496-.438 4.642-4.096L9.504.438 4.862 4.534h.992L1.496.69.504 1.812z"></path></svg>
                                    </span>
                                    <span className="order__summary--final__price">$227.70</span>
                                </span>
                            </summary>
                            <div className="order__summary--section">
                                <div className="cart__table checkout__product--table">
                                    <table className="summary__table">
                                        <tbody className="summary__table--body">
                                            <tr className=" summary__table--items">
                                                <td className=" summary__table--list">
                                                    <div className="product__image two  d-flex align-items-center">
                                                        <div className="product__thumbnail border-radius-5">
                                                            <a href="/product"><img className="border-radius-5" src="assets/img/product/small-product7.png" alt="cart-product" /></a>
                                                            <span className="product__thumbnail--quantity">1</span>
                                                        </div>
                                                        <div className="product__description">
                                                            <h3 className="product__description--name h4"><a href="/product">Fresh-whole-fish </a></h3>
                                                            <span className="product__description--variant">COLOR: Blue</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=" summary__table--list">
                                                    <span className="cart__price">£65.00</span>
                                                </td>
                                            </tr>
                                            <tr className="summary__table--items">
                                                <td className=" summary__table--list">
                                                    <div className="cart__product d-flex align-items-center">
                                                        <div className="product__thumbnail border-radius-5">
                                                            <a href="/product"><img className="border-radius-5" src="assets/img/product/small-product2.png" alt="cart-product" /></a>
                                                            <span className="product__thumbnail--quantity">1</span>
                                                        </div>
                                                        <div className="product__description">
                                                            <h3 className="product__description--name h4"><a href="/product">Vegetable-healthy</a></h3>
                                                            <span className="product__description--variant">COLOR: Green</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=" summary__table--list">
                                                    <span className="cart__price">£82.00</span>
                                                </td>
                                            </tr>
                                            <tr className=" summary__table--items">
                                                <td className=" summary__table--list">
                                                    <div className="cart__product d-flex align-items-center">
                                                        <div className="product__thumbnail border-radius-5">
                                                            <a href="/product"><img className="border-radius-5" src="assets/img/product/small-product4.png" alt="cart-product" /></a>
                                                            <span className="product__thumbnail--quantity">1</span>
                                                        </div>
                                                        <div className="product__description">
                                                            <h3 className="product__description--name h4"><a href="/product">Raw-onions-surface</a></h3>
                                                            <span className="product__description--variant">COLOR: White</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=" summary__table--list">
                                                    <span className="cart__price">£78.00</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                                <div className="checkout__discount--code">
                                    <form className="d-flex" action="#">
                                        <label>
                                            <input className="checkout__discount--code__input--field border-radius-5" placeholder="Gift card or discount code"  type="text" />
                                        </label>
                                        <button className="checkout__discount--code__btn btn border-radius-5" type="submit">Apply</button>
                                    </form>
                                </div>
                                <div className="checkout__total">
                                    <table className="checkout__total--table">
                                        <tbody className="checkout__total--body">
                                            <tr className="checkout__total--items">
                                                <td className="checkout__total--title text-left">Subtotal </td>
                                                <td className="checkout__total--amount text-right">$860.00</td>
                                            </tr>
                                            <tr className="checkout__total--items">
                                                <td className="checkout__total--title text-left">Shipping</td>
                                                <td className="checkout__total--calculated__text text-right">Calculated at next step</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="checkout__total--footer">
                                            <tr className="checkout__total--footer__items">
                                                <td className="checkout__total--footer__title checkout__total--footer__list text-left">Total </td>
                                                <td className="checkout__total--footer__amount checkout__total--footer__list text-right">$860.00</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </details>
                    </header>
                    <main className="main__content_wrapper section--padding pt-0">
                        <form action="#">
                            <div className="checkout__content--step section__shipping--address pt-0">
                                <div className="section__header checkout__header--style3 position__relative mb-25">
                                    <span className="checkout__order--number">Order #{orderDetails.id}</span>
                                    <h2 className="section__header--title h3">Thank you submission</h2>
                                    <div className="checkout__submission--icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25.995" height="25.979" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96"/></svg>
                                    </div>
                                </div>
                                <div className="order__confirmed--area border-radius-5 mb-15">
                                    <h3 className="order__confirmed--title h4">Your order is confirmed</h3>
                                    <p className="order__confirmed--desc">You,ll receive a confirmation email with your order number shortly</p>
                                </div>
                                <div className="customer__information--area border-radius-5">
                                    <h3 className="customer__information--title h4">Customer Information</h3>
                                    <div className="customer__information--inner d-flex">
                                        <div className="customer__information--list">
                                            <div className="customer__information--step">
                                                <h4 className="customer__information--subtitle h5">Contact information</h4>
                                                <ul>
                                                    <li><a className="customer__information--text__link" href="#">{orderDetails.email}</a></li>
                                                </ul>
                                            </div>
                                            <div className="customer__information--step">
                                                <h4 className="customer__information--subtitle h5">Shipping address</h4>
                                                <ul>
                                                    <li><span className="customer__information--text">{orderDetails.ship_first_name}</span></li>
                                                    <li><span className="customer__information--text">{orderDetails.ship_add}</span></li>
                                                    <li><span className="customer__information--text">{orderDetails.ship_city}</span></li>
                                                    <li><span className="customer__information--text">{orderDetails.ship_country}</span></li>
                                                </ul>
                                            </div>
                                            {/* <div className="customer__information--step">
                                                <h4 className="customer__information--subtitle h5">Shipping method</h4>
                                                <ul>
                                                    <li><span className="customer__information--text">Amin</span></li>
                                                    <li><span className="customer__information--text">Rajging</span></li>
                                                    <li><span className="customer__information--text">Dhaka 12119</span></li>
                                                    <li><span className="customer__information--text">Bangladesh</span></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        <div className="customer__information--list">
                                            <div className="customer__information--step">
                                                <h4 className="customer__information--subtitle h5">Payment method</h4>
                                                <ul>
                                                    <li><span className="customer__information--text">Cash on delivary</span></li>
                                                </ul>
                                            </div>
                                            <div className="customer__information--step">
                                                <h4 className="customer__information--subtitle h5">Billing Address</h4>
                                                <ul>
                                                    <li><span className="customer__information--text">{orderDetails.bill_first_name || orderDetails.ship_first_name || 'no information'}</span></li>
                                                    <li><span className="customer__information--text">{orderDetails.bill_add || orderDetails.ship_add || 'no information'}</span></li>
                                                    <li><span className="customer__information--text">{orderDetails.bill_city || orderDetails.ship_city || 'no information'}</span></li>
                                                    <li><span className="customer__information--text">{orderDetails.bill_country || orderDetails.ship_country || 'no information'}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout__content--step__footer d-flex align-items-center">
                                <a className="continue__shipping--btn btn border-radius-5" href="/checkout-4">My Orders</a>
                                <a className="previous__link--content" href="/cart">Return to shipping</a>
                            </div>
                        </form>
                    </main>
                    <footer className="main__footer main__footer--wrapper">
                        <p className="copyright__content">Copyright © 2022 <a className="copyright__content--link text__primary" href="/">Grocee</a> . All Rights Reserved.Design By Grocee</p>
                    </footer>
                </div>
               
            </div>
        </div>
    </div>
    {/* <!-- End checkout page area --> */}
    </>
  );
};

export default Checkout3;