import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Product = () => {

    const { id } = useParams(); // Get product ID from URL
    const [images, setImages] = useState([]);
    const [products, setProducts] = useState([]);
    const [contents, setContents] = useState([]);
    const [gallerys, setGallery] = useState([]);
    const [relatedproducts, setRelated] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1); // Default quantity
    const [product, setProduct] = useState({});
    const [cart, setCart] = useState([]);
    const minicartAnchorRef = React.useRef(null);
    const [wishlist, setWishlist] = useState([]);
    const [currentImage, setCurrentImage] = useState(null);
    const [isGalleryImage, setIsGalleryImage] = useState(false);
    const [variants, setVariants] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [comments, setComments] = useState([]);
    const [filteredComments, setFilteredComments] = useState([]);

    // Default to the first product image if currentImage is not set
    const displayedImage = currentImage || (products.length > 0 ? products[0].image : '');
  
    // Handle component mount and reset states
    useEffect(() => {
      setCurrentImage(null);
      setIsGalleryImage(false);
    }, [products]);


 // Fetch product details
 /*useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch product details
        const productResponse = await axios.get(`https://freshbitegrocers.com/admin/public/api/admin/product/view/${id}`);
    
        if (productResponse.data && productResponse.data.product) {
          // Set product-related data
          setProducts([productResponse.data.product]);
          setImages([productResponse.data.image]);
          setContents([productResponse.data.content]);
          setGallery([productResponse.data.gallery]);
          setRelated(productResponse.data.related);
    
          // Fetch variants and attributes related to the product
          const variantResponse = await axios.get(`https://freshbitegrocers.com/admin/public/api/admin/attribute_varients`, {
            params: { product_id: id }, // using 'id' as the product ID
          });
  
          console.log('Variants API Response:', variantResponse.data); // Log the response
  
          if (variantResponse.data && variantResponse.data.length > 0) {
            setVariants(variantResponse.data);
          } else {
            console.error('No variants found or invalid response data:', variantResponse.data);
          }
        } else {
          console.error('Invalid response data for product:', productResponse.data);
        }
      } catch (error) {
        console.error('Error fetching product and variants:', error);
        setError('Failed to fetch product and variants data.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);*/
  
  // Fetch product details and variants
useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const productResponse = await axios.get(`https://freshbitegrocers.com/admin/public/api/admin/product/view/${id}`);
        if (productResponse.data && productResponse.data.product) {
          // Set product-related data
          setProducts([productResponse.data.product]);
          setImages([productResponse.data.image]);
          setContents([productResponse.data.content]);
          setGallery([productResponse.data.gallery]);
          setRelated(productResponse.data.related);
          
          // Fetch and set variants with attributes
          if (productResponse.data.variants) {
            setVariants(productResponse.data.variants);
          }

          const productId = productResponse.data.product.id; // Assuming you have this product ID

          const commentsResponse = await fetch(`https://freshbitegrocers.com/admin/public/api/admin/comment/view?product_id=${productId}`);
          const commentsData = await commentsResponse.json();
          setComments(commentsData);

        } else {
          console.error('Invalid response data for product:', productResponse.data);
        }
      } catch (error) {
        console.error('Error fetching product and variants:', error);
        setError('Failed to fetch product and variants data.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);
  

  // Add to cart
  /*const handleAddToCart = async (product) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('User not authenticated.');
      navigate('/login');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://freshbitegrocers.com/admin/public/api/admin/cart/add',
        {
          product_id: product.id,
          price: product.price, // Use the product's price from the API
          quantity: quantity // Set a default quantity, or pass the desired quantity

        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) {
        //alert('Item added to cart successfully!');
        if (minicartAnchorRef.current) {
            console.log('Scrolling to the anchor tag...');
            minicartAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
            // Alternatively, trigger a click event if needed
            // minicartAnchorRef.current.click();
          } else {
            console.error('Anchor ref is not set.');
          }
  
        setCart([...cart, product.id]);
        //navigate('/cart'); // Redirect to cart page
      } else {
        console.error('Unexpected response:', response);
        setError('Failed to add item to cart.');
      }
    } catch (err) {
      setError('Failed to add item to cart.');
      console.error('Error adding item to cart:', err);
    } finally {
      setLoading(false);
    }
  };*/
  // Example function to handle attribute change
const handleAttributeChange = (variantId, attributeId, attributeName) => {
    setSelectedAttributes(prevState => ({
      ...prevState,
      [variantId]: { attributeId, attributeName }
    }));
  };

const handleAddToCart = async (product) => {
  const token = localStorage.getItem('auth_token');
  if (!token) {
    setError('User not authenticated.');
    navigate('/login');
    return;
  }

  setLoading(true);
  setError('');

  // Collect selected variant and attribute data
  const variantAttributes = Object.values(selectedAttributes);

  try {
    const response = await axios.post(
      'https://freshbitegrocers.com/admin/public/api/admin/cart/add',
      {
        product_id: product.id,
        price: product.price,
        quantity: quantity,
        variant_attributes: variantAttributes, // Include selected variants and attributes
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 201) {
      if (minicartAnchorRef.current) {
        console.log('Scrolling to the anchor tag...');
        minicartAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error('Anchor ref is not set.');
      }

      setCart([...cart, product.id]);
    } else {
      console.error('Unexpected response:', response);
      setError('Failed to add item to cart.');
    }
  } catch (err) {
    setError('Failed to add item to cart.');
    console.error('Error adding item to cart:', err);
  } finally {
    setLoading(false);
  }
};
 

  // Function to handle quantity change
  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > 0) {
      setQuantity(newQuantity);
    }
  };


  
  // Add to wishlist
const handleAddToWishlist = async (product) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('User not authenticated.');
      window.location.href = '/login';
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://freshbitegrocers.com/admin/public/api/admin/wishlist/add',
        {
          product_id: product.id,
          quantity:1,
          price: product.price,
          // You might want to include more fields if necessary
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) {
        setWishlist([...wishlist, product.id]);
        //window.location.href = '/wishlist';
      } else {
        console.error('Unexpected response:', response);
        setError('Failed to add item to wishlist.');
        window.location.href = '/login';
      }
    } catch (err) {
      setError('Failed to add item to wishlist.');
      console.error('Error adding item to wishlist:', err);
    } finally {
      setLoading(false);
    }
};
    
 

   // State to manage form data
   const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [review, setReview] = useState(0);
    const [product_id, setProductId] = useState(''); 
    
    // Use the first product's id or any specific logic to set the product_id
    useEffect(() => {
        if (products.length > 0) {
            setProductId(products[0].id); // Initialize product_id with the first product's ID
        }
    }, [products]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: name,
            email: email,
            comment: comment,
            review: review,
            product_id: product_id, // Ensure product_id is correctly set
        };

        try {
            const response = await axios.post('https://freshbitegrocers.com/admin/public/api/admin/comment', formData);
            if (response.status === 201) {
                alert('Comment submitted successfully!');
                // Clear the form
                setName('');
                setEmail('');
                setComment('');
                setReview(0);
                setProductId(''); // Reset product_id if needed
            } else {
                throw new Error('Unexpected response code');
            }
        } catch (error) {
            console.error('There was an error submitting the comment:', error);
            setError('Failed to submit comment. Please try again.');
        }
    };

   

  return (
    <>
    <main className="main__content_wrapper">
        
        {/* <!-- Start breadcrumb section --> */}
        {/* <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Product Details</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Product Details</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- End breadcrumb section --> */}

        {/* <!-- Start product details section --> */}
        <section className="product__details--section section--padding">
            <div className="container">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'-60px',marginBottom:'20px'}} id="product_bread_curm">
            <ul className="breadcrumb__content--menu d-flex">
                <li className="breadcrumb__content--menu__items"><a className="text-black" href="/">Home</a></li>
                <li className="breadcrumb__content--menu__items"><span className="text-black">Product Details</span></li>
            </ul>
            </div>
                <div className="row row-cols-lg-2 row-cols-md-2 text-left">
                    <div className="col">
               
          
                    <div className="product__details--media">
      <div className="product__media--preview swiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="product__media--preview__items">
              <a
                className="product__media--preview__items--link glightbox"
                data-gallery="product-media-preview"
                href={`https://freshbitegrocers.com/admin/public/${isGalleryImage ? 'gallery' : 'images'}/${displayedImage}`}
              >
                <img
                  className="product__media--preview__items--img"
                  src={`https://freshbitegrocers.com/admin/public/${isGalleryImage ? 'gallery' : 'images'}/${displayedImage}`}
                  alt="product-media-img"
                />
              </a>
              {/* <div className="product__media--view__icon">
                <a
                  className="product__media--view__icon--link glightbox"
                  href={`https://freshbitegrocers.com/admin/public/gallery/${image}`}
                  data-gallery="product-media-preview"
                >
                  <svg
                    className="product__items--action__btn--svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.51"
                    height="22.443"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    ></path>
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M338.29 338.29L448 448"
                    ></path>
                  </svg>
                  <span className="visually-hidden">product view</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="product__media--nav swiper">
        <div className="swiper-wrapper">
          {products.map((product, index) => {
            let gallery = product.gallery;

            // Parse the gallery JSON string if necessary
            if (typeof gallery === 'string') {
              try {
                gallery = JSON.parse(gallery);
              } catch (error) {
                console.error('Failed to parse gallery JSON:', error);
                gallery = [];
              }
            }

            return gallery.map((image, imgIndex) => (
              <div className="swiper-slide" key={`${index}-${imgIndex}`}>
                <div className="product__media--nav__items">
                  <img
                    className="product__media--nav__items--img"
                    src={`https://freshbitegrocers.com/admin/public/gallery/${image}`}
                    alt="product-nav-img"
                    onClick={() => {
                      setCurrentImage(image);
                      setIsGalleryImage(true);
                    }}
                  />
                </div>
              </div>
            ));
          })}
        </div>

        <div className="swiper__nav--btn swiper-button-next"></div>
        <div className="swiper__nav--btn swiper-button-prev"></div>
      </div>
    </div>   {/* <div className="product__details--media">
                            <div className="product__media--preview  swiper">
                                <div className="swiper-wrapper">
                                {products.map((image, index) => (
                                    <div className="swiper-slide"  key={index}>
                                        <div className="product__media--preview__items">
                                            <a className="product__media--preview__items--link glightbox" data-gallery="product-media-preview" href={`https://freshbitegrocers.com/admin/public/images/${image.image}`}><img className="product__media--preview__items--img" src={`https://freshbitegrocers.com/admin/public/images/${image.image}`} alt="product-media-img" /></a>
                                            <div className="product__media--view__icon">
                                                <a className="product__media--view__icon--link glightbox" href={`https://freshbitegrocers.com/admin/public/images/${image.image}`}data-gallery="product-media-preview">
                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="22.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"></path><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"></path></svg>
                                                    <span className="visually-hidden">product view</span> 
                                                </a>
                                            </div>
                                        </div>
                                    </div>                                   
                                ))}
                                </div>
                            </div>
                            <div className="product__media--nav swiper">
                            <div className="swiper-wrapper">
                                    {products.map((product, index) => {
                                        let gallery = product.gallery;

                                        // Parse the gallery JSON string if necessary
                                        if (typeof gallery === 'string') {
                                            try {
                                                gallery = JSON.parse(gallery);
                                            } catch (error) {
                                                console.error("Failed to parse gallery JSON:", error);
                                                gallery = [];
                                            }
                                        }

                                        return gallery.map((image, imgIndex) => (
                                            <div className="swiper-slide" key={`${index}-${imgIndex}`}>
                                                <div className="product__media--nav__items">
                                                    <img
                                                        className="product__media--nav__items--img"
                                                        src={`https://freshbitegrocers.com/admin/public/gallery/${image}`}
                                                        alt="product-nav-img"
                                                    />
                                                </div>
                                            </div>
                                        ));
                                    })}
                                </div>

                                <div className="swiper__nav--btn swiper-button-next"></div>
                                <div className="swiper__nav--btn swiper-button-prev"></div>
                            </div>
                        </div> */}
                    </div>   
                    <div className="col">
                    {products.map((product, index) => (
                        <div className="product__details--info" key={index}>
                            <form action="#">
                                <h2 className="product__details--info__title mb-15">{product.name}</h2>
                                <div className="product__details--info__price mb-15">
                                    <span className="current__price">${product.price}</span>
                                    <span className="old__price">$68.00</span>
                                </div>
                                <div className="product__items--rating d-flex align-items-center mb-15">
                                    <ul className="d-flex">
                                        
                                        <li className="product__items--rating__list">
                                            <span className="product__items--rating__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.105" height="14.732" viewBox="0 0 10.105 9.732">
                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li className="product__items--rating__list">
                                            <span className="product__items--rating__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.105" height="14.732" viewBox="0 0 10.105 9.732">
                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li className="product__items--rating__list">
                                            <span className="product__items--rating__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.105" height="14.732" viewBox="0 0 10.105 9.732">
                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li className="product__items--rating__list">
                                            <span className="product__items--rating__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.105" height="14.732" viewBox="0 0 10.105 9.732">
                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </li>
                                        <li className="product__items--rating__list">
                                            <span className="product__items--rating__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.105" height="14.732" viewBox="0 0 10.105 9.732">
                                                    <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="#c7c5c2"/>
                                                </svg> 
                                            </span>
                                        </li>
                                    </ul>
                                    <span className="product__items--rating__count--number">(24)</span>
                                </div>
                                <p className="product__details--info__desc mb-20">{product.short_description}</p>
                                 <div className="product__variant">
                                     {/* <div className="product__variant--list mb-10">
                                        <fieldset className="variant__input--fieldset">
                                            <legend className="product__variant--title mb-8">Color :</legend>
                                            <div className="variant__color d-flex">
                                                <div className="variant__color--list">
                                                    <input id="color-red1" name="color" type="radio" checked />
                                                    <label className="variant__color--value red" htmlFor="color-red1" title="Red"><img className="variant__color--value__img" src="assets/img/product/product1.png" alt="variant-color-img" /></label>
                                                </div>
                                                <div className="variant__color--list">
                                                    <input id="color-red2" name="color" type="radio" />
                                                    <label className="variant__color--value red" htmlFor="color-red2" title="Black"><img className="variant__color--value__img" src="assets/img/product/product2.png" alt="variant-color-img" /></label>
                                                </div>
                                                <div className="variant__color--list">
                                                    <input id="color-red3" name="color" type="radio" />
                                                    <label className="variant__color--value red" htmlFor="color-red3" title="Pink"><img className="variant__color--value__img" src="assets/img/product/product3.png" alt="variant-color-img" /></label>
                                                </div>
                                                <div className="variant__color--list">
                                                    <input id="color-red4" name="color" type="radio" />
                                                    <label className="variant__color--value red" htmlFor="color-red4" title="Orange"><img className="variant__color--value__img" src="assets/img/product/product4.png" alt="variant-color-img" /></label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>  */}
                              {/* {variants.map(variant => (
        <div className="product__variant--list mb-20" key={variant.id}>
          <fieldset className="variant__input--fieldset">
            <legend className="product__variant--title mb-8">
              {variant.variant_name} :
            </legend>

            <ul className="variant__size d-flex">
              {variant.attributes && variant.attributes.length > 0 ? (
                variant.attributes.map(attribute => (
                  <li className="variant__size--list" key={attribute.id}>
                    <input
                      id={`attribute-${attribute.id}`}
                      name={`attribute-${variant.id}`}
                      type="radio"
                      onChange={() => handleAttributeChange(variant.id, attribute.id, variant.variant_name, attribute.attribute_name)}
                    />
                    <label className="variant__size--value red" htmlFor={`attribute-${attribute.id}`}>
                      {attribute.attribute_name}
                    </label>
                  </li>
                ))
              ) : (
                <p>No attributes available for this variant</p>
              )}
            </ul>
          </fieldset>
        </div>
      ))}   */}
        <div className="product__variant--list quantity d-flex align-items-center mb-20">
                                        {/* <div className="quantity__box">
                                            <button type="button" className="quantity__value quickview__value--quantity decrease" aria-label="quantity value" value="Decrease Value">-</button>
                                            <label>
                                                <input type="number" className="quantity__number quickview__value--number" value="1" data-counter />
                                            </label>
                                            <button type="button" className="quantity__value quickview__value--quantity increase" aria-label="quantity value" value="Increase Value">+</button>
                                        </div> */}
                                        {/* Quantity Box */}
                                        <div className="quantity__box">
                                            <button
                                                type="button"
                                                className="quantity__value quickview__value--quantity decrease"
                                                aria-label="quantity value"
                                                onClick={() => handleQuantityChange(quantity - 1)}
                                            >
                                                -
                                            </button>
                                            <label>
                                                <input
                                                    type="number"
                                                    className="quantity__number quickview__value--number"
                                                    value={quantity}
                                                    onChange={(e) => handleQuantityChange(Number(e.target.value))}
                                                    data-counter
                                                />
                                            </label>
                                            <button
                                                type="button"
                                                className="quantity__value quickview__value--quantity increase"
                                                aria-label="quantity value"
                                                onClick={() => handleQuantityChange(quantity + 1)}
                                            >
                                                +
                                            </button>
                                        </div>
                                       {/* Add to Cart Button */}
                                       <button
    type="button" // Ensure this is set to prevent default form submission
    className="btn quickview__cart--btn"
    onClick={(e) => {
        e.preventDefault(); 
        if (cart.includes(product.id)) {
            window.location.href = '/cart';
        } else {
            handleAddToCart(product);
        };
    }}
>
{cart.includes(product.id) ? "View Cart" : "+ Add to cart"}
</button>  
                                    </div>
                                    {/* <div className="product__variant--list mb-15">
                                        <a className="variant__wishlist--icon mb-15" href="/wishlist" title="Add to wishlist">
                                            <svg className="quickview__variant--wishlist__svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
                                            Add to Wishlist
                                        </a>
                                        <button className="variant__buy--now__btn btn" type="submit">Buy it now</button>
                                    </div> */}
                                    <div className="product__variant--list mb-15">
                                        <div className="product__details--info__meta">
                                            <p className="product__details--info__meta--list"><strong>Barcode:</strong>  <span> {product.barcode}</span> </p>
                                            <p className="product__details--info__meta--list"><strong>Sky:</strong>  <span>{product.sky}</span> </p>
                                            <p className="product__details--info__meta--list"><strong>Vendor:</strong>  <span>{product.vendor}</span> </p>
                                            <p className="product__details--info__meta--list"><strong>Type:</strong>  <span>{product.type}</span> </p>
                                        </div>
                                    </div> 
                                </div> 
                                <div className="quickview__social d-flex align-items-center mb-15">
                                    <label className="quickview__social--title">Social Share:</label>
                                    <ul className="quickview__social--wrapper mt-0 d-flex">
                                        <li className="quickview__social--list">
                                            <a className="quickview__social--icon" target="_blank" href="https://www.facebook.com">
                                                <svg  xmlns="http://www.w3.org/2000/svg" width="7.667" height="16.524" viewBox="0 0 7.667 16.524">
                                                    <path  data-name="Path 237" d="M967.495,353.678h-2.3v8.253h-3.437v-8.253H960.13V350.77h1.624v-1.888a4.087,4.087,0,0,1,.264-1.492,2.9,2.9,0,0,1,1.039-1.379,3.626,3.626,0,0,1,2.153-.6l2.549.019v2.833h-1.851a.732.732,0,0,0-.472.151.8.8,0,0,0-.246.642v1.719H967.8Z" transform="translate(-960.13 -345.407)" fill="currentColor"/>
                                                </svg>
                                                <span className="visually-hidden">Facebook</span>
                                            </a>
                                        </li>
                                        <li className="quickview__social--list">
                                            <a className="quickview__social--icon" target="_blank" href="https://twitter.com">
                                                <svg  xmlns="http://www.w3.org/2000/svg" width="16.489" height="13.384" viewBox="0 0 16.489 13.384">
                                                    <path  data-name="Path 303" d="M966.025,1144.2v.433a9.783,9.783,0,0,1-.621,3.388,10.1,10.1,0,0,1-1.845,3.087,9.153,9.153,0,0,1-3.012,2.259,9.825,9.825,0,0,1-4.122.866,9.632,9.632,0,0,1-2.748-.4,9.346,9.346,0,0,1-2.447-1.11q.4.038.809.038a6.723,6.723,0,0,0,2.24-.376,7.022,7.022,0,0,0,1.958-1.054,3.379,3.379,0,0,1-1.958-.687,3.259,3.259,0,0,1-1.186-1.666,3.364,3.364,0,0,0,.621.056,3.488,3.488,0,0,0,.885-.113,3.267,3.267,0,0,1-1.374-.631,3.356,3.356,0,0,1-.969-1.186,3.524,3.524,0,0,1-.367-1.5v-.057a3.172,3.172,0,0,0,1.544.433,3.407,3.407,0,0,1-1.1-1.214,3.308,3.308,0,0,1-.4-1.609,3.362,3.362,0,0,1,.452-1.694,9.652,9.652,0,0,0,6.964,3.538,3.911,3.911,0,0,1-.075-.772,3.293,3.293,0,0,1,.452-1.694,3.409,3.409,0,0,1,1.233-1.233,3.257,3.257,0,0,1,1.685-.461,3.351,3.351,0,0,1,2.466,1.073,6.572,6.572,0,0,0,2.146-.828,3.272,3.272,0,0,1-.574,1.083,3.477,3.477,0,0,1-.913.8,6.869,6.869,0,0,0,1.958-.546A7.074,7.074,0,0,1,966.025,1144.2Z" transform="translate(-951.23 -1140.849)" fill="currentColor"/>
                                                </svg>
                                                <span className="visually-hidden">Twitter</span>
                                            </a>
                                        </li>
                                        <li className="quickview__social--list">
                                            <a className="quickview__social--icon" target="_blank" href="https://www.instagram.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.497" height="17.492" viewBox="0 0 19.497 19.492">
                                                    <path  data-name="Icon awesome-instagram" d="M9.747,6.24a5,5,0,1,0,5,5A4.99,4.99,0,0,0,9.747,6.24Zm0,8.247A3.249,3.249,0,1,1,13,11.238a3.255,3.255,0,0,1-3.249,3.249Zm6.368-8.451A1.166,1.166,0,1,1,14.949,4.87,1.163,1.163,0,0,1,16.115,6.036Zm3.31,1.183A5.769,5.769,0,0,0,17.85,3.135,5.807,5.807,0,0,0,13.766,1.56c-1.609-.091-6.433-.091-8.042,0A5.8,5.8,0,0,0,1.64,3.13,5.788,5.788,0,0,0,.065,7.215c-.091,1.609-.091,6.433,0,8.042A5.769,5.769,0,0,0,1.64,19.341a5.814,5.814,0,0,0,4.084,1.575c1.609.091,6.433.091,8.042,0a5.769,5.769,0,0,0,4.084-1.575,5.807,5.807,0,0,0,1.575-4.084c.091-1.609.091-6.429,0-8.038Zm-2.079,9.765a3.289,3.289,0,0,1-1.853,1.853c-1.283.509-4.328.391-5.746.391S5.28,19.341,4,18.837a3.289,3.289,0,0,1-1.853-1.853c-.509-1.283-.391-4.328-.391-5.746s-.113-4.467.391-5.746A3.289,3.289,0,0,1,4,3.639c1.283-.509,4.328-.391,5.746-.391s4.467-.113,5.746.391a3.289,3.289,0,0,1,1.853,1.853c.509,1.283.391,4.328.391,5.746S17.855,15.705,17.346,16.984Z" transform="translate(0.004 -1.492)" fill="currentColor"></path>
                                                </svg>
                                                <span className="visually-hidden">Instagram</span>
                                            </a>
                                        </li>
                                        <li className="quickview__social--list">
                                            <a className="quickview__social--icon" target="_blank" href="https://www.youtube.com">
                                                <svg  xmlns="http://www.w3.org/2000/svg" width="16.49" height="11.582" viewBox="0 0 16.49 11.582">
                                                    <path  data-name="Path 321" d="M967.759,1365.592q0,1.377-.019,1.717-.076,1.114-.151,1.622a3.981,3.981,0,0,1-.245.925,1.847,1.847,0,0,1-.453.717,2.171,2.171,0,0,1-1.151.6q-3.585.265-7.641.189-2.377-.038-3.387-.085a11.337,11.337,0,0,1-1.5-.142,2.206,2.206,0,0,1-1.113-.585,2.562,2.562,0,0,1-.528-1.037,3.523,3.523,0,0,1-.141-.585c-.032-.2-.06-.5-.085-.906a38.894,38.894,0,0,1,0-4.867l.113-.925a4.382,4.382,0,0,1,.208-.906,2.069,2.069,0,0,1,.491-.755,2.409,2.409,0,0,1,1.113-.566,19.2,19.2,0,0,1,2.292-.151q1.82-.056,3.953-.056t3.952.066q1.821.067,2.311.142a2.3,2.3,0,0,1,.726.283,1.865,1.865,0,0,1,.557.49,3.425,3.425,0,0,1,.434,1.019,5.72,5.72,0,0,1,.189,1.075q0,.095.057,1C967.752,1364.1,967.759,1364.677,967.759,1365.592Zm-7.6.925q1.49-.754,2.113-1.094l-4.434-2.339v4.66Q958.609,1367.311,960.156,1366.517Z" transform="translate(-951.269 -1359.8)" fill="currentColor"/>
                                                </svg>
                                                <span className="visually-hidden">Youtube</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> 
                                <div class="guarantee__safe--checkout">
                                    <h5 class="guarantee__safe--checkout__title">Guaranteed Safe Checkout</h5>
                                    <img class="guarantee__safe--checkout__img" src="https://freshbitegrocers.com/assets/img/other/safe-checkout.png" alt="Payment Image" />
                                </div>
                            </form>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End product details section --> */}

        {/* <!-- Start product details tab section --> */}
        <section className="product__details--tab__section section--padding">
            <div className="container">
                <div className="row row-cols-1 text-left">
                    <div className="col">
                        <ul className="product__tab--one product__details--tab d-flex mb-30">
                            <li className="product__details--tab__list active" data-toggle="tab" data-target="#description">Description</li>
                            <li className="product__details--tab__list" data-toggle="tab" data-target="#reviews">Product Reviews</li>
                            <li className="product__details--tab__list" data-toggle="tab" data-target="#information">Additional Info</li>
                            <li className="product__details--tab__list" data-toggle="tab" data-target="#custom">Custom Content</li>
                        </ul>
                        <div className="product__details--tab__inner border-radius-10">
                        {products.map((content, index) => (
                            <div className="tab_content" key={index}>
                                <div id="description" className="tab_pane active show">
                                    <div className="product__tab--content">
                                        <div className="product__tab--content__step mb-30">
                                            {content.long_description}
                                        </div>
                                    </div> 
                                </div>
                                
                                <div id="reviews" className="tab_pane">
                                    <div className="product__reviews">
                                        <div className="product__reviews--header">
                                            <h2 className="product__reviews--header__title h3 mb-10">Customer Reviews</h2>
                                            <div className="reviews__ratting d-flex align-items-center">
                                                {/* <ul className="d-flex">
                                                    <li className="reviews__ratting--list">
                                                       <span className="reviews__ratting--icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="reviews__ratting--list">
                                                       <span className="reviews__ratting--icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="reviews__ratting--list">
                                                       <span className="reviews__ratting--icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="reviews__ratting--list">
                                                       <span className="reviews__ratting--icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="reviews__ratting--list">
                                                        <span className="reviews__ratting--icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="#c7c5c2"/>
                                                            </svg> 
                                                        </span>
                                                    </li>
                                                </ul> */}
                                                {/* <span className="reviews__summary--caption">Based on 2 reviews</span> */}
                                            </div>
                                            <a className="actions__newreviews--btn btn" href="#writereview">Write A Review</a>
                                        </div>
                                        <div className="reviews__comment--area">
        {comments.length === 0 ? (
          <p>Be the first commenter!</p>
        ) : (
          comments.map(comment => (
            <div className="reviews__comment--list d-flex" key={comment.id}>
              <div className="reviews__comment--content">
                <div className="reviews__comment--top d-flex justify-content-between">
                  <div className="reviews__comment--top__left">
                    <h3 className="reviews__comment--content__title h4">{comment.name}</h3>
                    <ul className="reviews__ratting d-flex">
                      {[...Array(5)].map((_, i) => (
                        <li className="reviews__ratting--list" key={i}>
                          <span className="reviews__ratting--icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                              <path data-name="star - Copy"
                                d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                transform="translate(0 -0.018)"
                                fill={i < parseInt(comment.review, 10) ? "currentColor" : "#c7c5c2"}
                              />
                            </svg>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <span className="reviews__comment--content__date">
                    {new Date(comment.created_at).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    })}
                  </span>
                </div>
                <p className="reviews__comment--content__desc">{comment.comment}</p>
              </div>
            </div>
          ))
        )}
      </div>
                                        
                                        <div id="writereview" className="reviews__comment--reply__area">
                                        
                                           
                                        <form onSubmit={handleSubmit}>
    <input 
        type="hidden" 
        placeholder="Product ID" 
        value={content.id} // Updated to use productId
        
    />
    <h3 className="reviews__comment--reply__title mb-15">Add a review</h3>
    <div className="reviews__ratting d-flex align-items-center mb-20">
        <ul className="d-flex">
            {[...Array(5)].map((_, index) => (
                <li key={index} className="reviews__ratting--list" onClick={() => setReview(index + 1)}>
                    <span className="reviews__ratting--icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.105" height="12.732" viewBox="0 0 10.105 9.732">
                            <path d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill={review > index ? "currentColor" : "#c7c5c2"} />
                        </svg>
                    </span>
                </li>
            ))}
        </ul>
    </div>
    <div className="row">
        <div className="col-12 mb-10">
            <textarea
                className="reviews__comment--reply__textarea"
                placeholder="Your Comments...."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            ></textarea>
        </div>
        <div className="col-lg-6 col-md-6 mb-15">
            <label>
                <input
                    className="reviews__comment--reply__input"
                    placeholder="Your Name...."
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </label>
        </div>
        <div className="col-lg-6 col-md-6 mb-15">
            <label>
                <input
                    className="reviews__comment--reply__input"
                    placeholder="Your Email...."
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </label>
        </div>
    </div>
    {error && <p style={{ color: 'red' }}>{error}</p>}
    <button className="btn text-white" data-hover="Submit" type="submit">SUBMIT</button>
</form>
                                        </div> 
                                    </div>    
                                </div>
                                <div id="information" className="tab_pane">
                                    <div className="product__tab--conten">
                                        <div className="product__tab--content__step mb-30">
                                            {content.additional_info}
                                            {/* <p className="product__tab--content__desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam provident sequi, nemo sapiente culpa nostrum rem eum perferendis quibusdam, magnam a vitae corporis! Magnam enim modi, illo harum suscipit tempore aut dolore doloribus deserunt voluptatum illum, est porro? Ducimus dolore accusamus impedit ipsum maiores, ea iusto temporibus numquam eaque mollitia fugiat laborum dolor tempora eligendi voluptatem quis necessitatibus nam ab?</p> */}
                                        </div>
                                    </div> 
                                </div>
                                <div id="custom" className="tab_pane">
                                    <div className="product__tab--content">
                                        <div className="product__tab--content__step mb-30">
                                            {content.custom_content}
                                            {/* <p className="product__tab--content__desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam provident sequi, nemo sapiente culpa nostrum rem eum perferendis quibusdam, magnam a vitae corporis! Magnam enim modi, illo harum suscipit tempore aut dolore doloribus deserunt voluptatum illum, est porro? Ducimus dolore accusamus impedit ipsum maiores, ea iusto temporibus numquam eaque mollitia fugiat laborum dolor tempora eligendi voluptatem quis necessitatibus nam ab?</p> */}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End product details tab section --> */}
        
       {/* <!-- Start product section --> */}
        <section className="product__section product__section--style3 section--padding">
            <div className="container product3__section--container">
                <div className="section__heading3 text-center mb-40">
                    <h2 className="section__heading3--maintitle">You may also like</h2>
                </div>
                <div className="product__section--inner product3__section--inner__padding product__section--style3__inner product__swiper--activation swiper">
                    <div className="swiper-wrapper">
                    {relatedproducts.map((related, index) => (
                                        <div className="swiper-slide" key={index}>
                                            <div className="product__items product_items2">
                                                <div className="product__items--thumbnail">
                                                    <a className="product__items--link" href={`/product/${related.id}`}>
                                                        <img className="product__items--img product__primary--img" src={`https://freshbitegrocers.com/admin/public/images/${related.image}`} alt="product-img" />
                                                       
                                                        <img className="product__items--img product__secondary--img" src={`https://freshbitegrocers.com/admin/public/images/${related.image}`} alt="product-img" />
                                                     
                                                    </a>
                                                    {/* <div className="product__badge">
                                                        <span className="product__badge--items sale">Sale</span>
                                                    </div> */}
                                                    <ul className="product__items--action">
                                                    <li className="product__items--action__list">
                                                        <a className="product__items--action__btn" onClick={() => handleAddToWishlist(related)}>
                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      {/* <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />   */}
                      <path
            d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
            fill={wishlist.includes(related.id) ? "#3CB815" : "none"}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
          />
                    </svg>
                    <span className="visually-hidden">Add to wishlist</span>
                  </a>
                                                        </li>
                                                        <li className="product__items--action__list">
                                                            <a className="product__items--action__btn" data-open="modal1" href={`/product/${related.id}`}>
                                                                <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448" />
                                                                </svg>
                                                                <span className="visually-hidden">Quick View</span>
                                                            </a>
                                                        </li>
                                                        {/* <li className="product__items--action__list">
                                                            <a className="product__items--action__btn" href="/compare">
                                                                <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M400 304l48 48-48 48M400 112l48 48-48 48M64 352h85.19a80 80 0 0066.56-35.62L256 256" />
                                                                    <path d="M64 160h85.19a80 80 0 0166.56 35.62l80.5 120.76A80 80 0 00362.81 352H416M416 160h-53.19a80 80 0 00-66.56 35.62L288 208" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                                                </svg>
                                                                <span className="visually-hidden">Compare</span>
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                                <div className="product__items--content text-center">
                                                    <a  className="add__to--cart__btn" onClick={(e) => {
        e.preventDefault(); 
        if (cart.includes(related.id)) {
            window.location.href = '/cart';
        } else {
            handleAddToCart(related);
        };
    }}>{cart.includes(related.id) ? "View Cart" : "+ Add to cart"}</a>
                                                    <h3 className="product__items--content__title style2 h4">
                                                        <a href={`/product/${related.id}`}>{related.name}</a>
                                                    </h3>
                                                    <div className="product__items--price">
                                                        <span className="current__price">${related.price}</span>
                                                        {related.old_price && <span className="old__price">${related.old_price}</span>}
                                                    </div>
                                                    <div className="product__items--sold__stocks d-flex justify-content-between">
                                                        <span className="product__items--sold__stocks--text">Total Sold: 1</span>
                                                        <span className="product__items--sold__stocks--text">Stocks: 35</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                    </div>
                    <div className="swiper__nav--btn swiper-button-next"></div>
                    <div className="swiper__nav--btn swiper-button-prev"></div>
                </div>
            </div>
        </section>
        {/* <!-- End product section --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}
    </main>    
    </>
  );
};

export default Product;