import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


   


const Cart = ({ initialPrice }) => {


 

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState(1); 
    const [cartItems, setCartItems] = useState([]); // State to store cart items
    const [error, setError] = useState(''); // State to store error messages
    const minicartAnchorRef = React.useRef(null);

    
    // Function to fetch cart data
    const fetchCartData = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                setError('User not authenticated.');
                return;
            }

            const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/cart', {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const items = response.data.items;
                if (items && items.length > 0) {
                    // Fetch product details for each item
                    const products = await Promise.all(items.map(item => fetchProductDetails(item.product_id)));
                    // Combine cart items with product details
                    const updatedCartItems = items.map((item, index) => ({
                        ...item,
                        ...products[index]
                    }));
                    setCartItems(updatedCartItems); // Set cartItems state with the combined data
                    setError(''); // Clear any previous error messages
                } else {
                    setError('No cart items found.');
                }
            } else {
                setError('Failed to fetch cart data.');
            }
        } catch (error) {
            setError('Error fetching cart data.');
            console.error('Fetch Error:', error);
        }
    };

    // Function to fetch product details by ID
    const fetchProductDetails = async (productId) => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get(`https://freshbitegrocers.com/admin/public/api/admin/product/${productId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const product = response.data;
                return {
                    product_name: product.name,
                    product_image: product.image
                };
            } else {
                throw new Error('Failed to fetch product details.');
            }
        } catch (error) {
            console.error('Product Fetch Error:', error);
            return {}; // Return empty object in case of error
        }
    };

    // Fetch cart data when the component mounts
    useEffect(() => {
        fetchCartData();
    }, []);

    //remove cart functionality
    const removeCartItem = async (itemId) => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                setError('User not authenticated.');
                return;
            }
    
            const response = await axios.delete(`https://freshbitegrocers.com/admin/public/api/admin/cart/remove/${itemId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if (response.status === 200) {
                // Remove the item from the state
                const updatedItems = cartItems.filter(item => item.id !== itemId);
                setCartItems(updatedItems);
                setError(''); // Clear any previous error messages
            } else {
                setError('Failed to remove cart item.');
            }
        } catch (error) {
            setError('Error removing cart item.');
            console.error('Remove Error:', error);
        }
    };

    //calculate cart product price
    const calculateTotals = () => {
        let subtotal = 0;
        let grandTotal = 0;

        cartItems.forEach(item => {
            const itemTotal = parseFloat(item.price) * item.quantity;
            subtotal += itemTotal;
        });

        grandTotal = subtotal; // For simplicity, if there are no taxes or shipping costs, grand total is the same as subtotal

        return {
            subtotal: subtotal.toFixed(2),
            grandTotal: grandTotal.toFixed(2)
        };
    };

    const { subtotal, grandTotal } = calculateTotals();


    // Handler to update the quantity of items
    const handleQuantityChange = (id, newQuantity) => {
        if (newQuantity < 1) return; // Prevent negative or zero quantities

        setCartItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, quantity: newQuantity } : item
            )
        );


    };

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            // Redirect to login if token is not present
            window.location.href = '/login';
        } else {
            // Simulate an API call or token validation if needed
            setLoading(false);
        }

        // Fetch products from API
        axios.get('https://freshbitegrocers.com/admin/public/api/admin/product')
            .then(response => {
                if (response.data && response.data.products) {
                    setProducts(response.data.products);
                } else {
                    console.error ('Invalid response data for products:', response.data);
                }
            })
            .catch(error => {
                console.error ('Error fetching products:', error);
            });
                
    }, [navigate]);

  
  // update cart
  const handleUpdateCart = async () => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('User not authenticated.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://freshbitegrocers.com/admin/public/api/admin/cart/update/{$id}',
        { cartItems },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        alert('Cart updated successfully!');
      } else {
        console.error('Unexpected response:', response);
        setError('Failed to update cart.');
      }
    } catch (err) {
      setError('Failed to update cart.');
      console.error('Error updating cart:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <main className="main__content_wrapper">
        
        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Shopping Cart</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Shopping Cart</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}

        {/* <!-- cart section start --> */}
        <section className="cart__section section--padding">
            <div className="container-fluid">
                <div className="cart__section--inner text-left">
                    <form action="#"> 
                        <h2 className="cart__title mb-40">Shopping Cart</h2>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cart__table">
                                    <table className="cart__table--inner">
                                        <thead className="cart__table--header">
                                            <tr className="cart__table--header__items">
                                                <th className="cart__table--header__list">Product</th>
                                                <th className="cart__table--header__list">Price</th>
                                                <th className="cart__table--header__list">Quantity</th>
                                                <th className="cart__table--header__list">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="cart__table--body">
                                        {error && <tr><td colSpan="4">{error}</td></tr>}
        {cartItems.map((item) => (
            <tr key={item.id}>
                <td className="cart__table--body__list">
                    <div className="cart__product d-flex align-items-center">
                        <button className="cart__remove--btn" 
                    aria-label="remove button" 
                    type="button" 
                    onClick={() => removeCartItem(item.id)}>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px">
                                <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
                            </svg>
                        </button>
                        <div className="cart__thumbnail">
                            <a href={`/product/${item.product.id}`}>
                                <img className="border-radius-5" src={`https://freshbitegrocers.com/admin/public/images/${item.product.image}`} alt="cart-product" />
                            </a>
                        </div>
                        <div className="cart__content">
                            <h3 className="cart__content--title h4">
                                <a href={`/product/${item.product.id}`}>{item.product.name}</a>
                            </h3>
                            <span className="cart__content--variant">VENDOR: {item.product.vendor}</span>
                            <span className="cart__content--variant">TYPE: {item.product.type}</span>
                            {/* <span className="cart__content--variant">COLOR: {item.color}</span>
                            <span className="cart__content--variant">WEIGHT: {item.weight} Kg</span> */}
                        </div>
                    </div>
                </td>
                <td className="cart__table--body__list">
                    <span className="cart__price">₹{parseFloat(item.price).toFixed(2)}</span>
                </td>
                <td className="cart__table--body__list">
                    <div className="quantity__box">
                        <button
                            type="button"
                            className="quantity__value quickview__value--quantity decrease"
                            aria-label="decrease quantity"
                            onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                        >
                            -
                        </button>
                         <label>
                            <input
                                type="number"
                                className="quantity__number quickview__value--number"
                                value={item.quantity}
                                onChange={(e) => handleQuantityChange(item.id, Number(e.target.value))}
                                data-counter
                                min="1"
                            />
                            
                        </label> 
                        <button
                            type="button"
                            className="quantity__value quickview__value--quantity increase"
                            aria-label="increase quantity"
                            onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                        >
                            +
                        </button>
                    </div>
                </td>
                <td className="cart__table--body__list">
                    <span className="cart__price end">₹{(parseFloat(item.price) * item.quantity).toFixed(2)}</span>
                </td>
            </tr>
        ))}

                                        </tbody>
                                    </table> 
                                    <div className="continue__shopping d-flex justify-content-between">
                                        <a className="continue__shopping--link" href="/shop">Continue shopping</a>
                                        {/* <button className="continue__shopping--clear" type="submit">Clear Cart</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="cart__summary border-radius-10">
                                    {/* <div className="coupon__code mb-30">
                                        <h3 className="coupon__code--title">Coupon</h3>
                                        <p className="coupon__code--desc">Enter your coupon code if you have one.</p>
                                        <div className="coupon__code--field d-flex">
                                            <label>
                                                <input className="coupon__code--field__input border-radius-5" placeholder="Coupon code" type="text" />
                                            </label>
                                            <button className="coupon__code--field__btn btn" type="submit">Apply Coupon</button>
                                        </div>
                                    </div> */}
                                    <div className="cart__note mb-20">
                                        <h3 className="cart__note--title">Note</h3>
                                        <p className="cart__note--desc">Add special instructions for your seller...</p>
                                        <textarea className="cart__note--textarea border-radius-5"></textarea>
                                    </div>
                                    <div className="cart__summary--total mb-20">
                                        <table className="cart__summary--total__table">
                                            <tbody>
                                                <tr className="cart__summary--total__list">
                                                    <td className="cart__summary--total__title text-left">SUBTOTAL</td>
                                                    <td className="cart__summary--amount text-right">₹{subtotal}</td>
                                                </tr>
                                                <tr className="cart__summary--total__list">
                                                    <td className="cart__summary--total__title text-left">GRAND TOTAL</td>
                                                    <td className="cart__summary--amount text-right">₹{grandTotal}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="cart__summary--footer">
                                        <p className="cart__summary--footer__desc">Shipping & taxes calculated at checkout</p>
                                        <ul className="d-flex justify-content-between">
                                            {/* <li><button className="cart__summary--footer__btn btn cart" type="button"  onClick={handleUpdateCart}>Update Cart</button></li> */}
                                            <li><a className="cart__summary--footer__btn btn checkout" href="/checkout">Check Out</a></li>
                                        </ul>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </form> 
                </div>
            </div>     
        </section>
        {/* <!-- cart section end --> */}


        {/* <!-- Start brand logo section --> */}
        <div className="brand__logo--section section--padding pt-5 mt-5">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="brand__logo--section__inner d-flex justify-content-between align-items-center">
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo1.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo2.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo3.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo4.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo5.png" alt="brand img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End brand logo section --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}

    </main>
    </>
  );
};

export default Cart;
