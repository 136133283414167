import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Checkout2 = () => {

    const navigate = useNavigate();

  // State to hold cart data
  const [loading, setLoading] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState('');
  const [products, setProducts] = useState([]);
  const [orderData, setOrderData] = useState({
    email: '',
    ship_add: '',
    ship_city: '',
    ship_country: ''
});

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      // Redirect to login if token is not present
      window.location.href = '/login';
      return; // Return early to prevent further execution
    }



        
    const fetchOrderData = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                throw new Error('User not authenticated');
            }
    
            const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/order/latest', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.status === 200) {
                setOrderData(response.data); // Assuming response.data is a single object
            } else {
                setOrderData(null); // Set to null if no data
            }
        } catch (err) {
            setError('Failed to fetch order data.');
            setOrderData(null); // Set to null in case of error
            console.error('Fetch Error:', err);
        }
    };
    
    fetchOrderData();
    
  }, [navigate]);

  //calculate cart product price
  const calculateTotals = () => {
    let subtotal = 0;
    let grandTotal = 0;

    cartItems.forEach(item => {
        const itemTotal = parseFloat(item.price) * item.quantity;
        subtotal += itemTotal;
    });

    grandTotal = subtotal; // For simplicity, if there are no taxes or shipping costs, grand total is the same as subtotal

    return {
        subtotal: subtotal.toFixed(2),
        grandTotal: grandTotal.toFixed(2)
    };
};

const { subtotal, grandTotal } = calculateTotals();




  return (
    <>        
    {/* <!-- Start checkout page area --> */}
    <div className="checkout__page--area">
        <div className="container">
            <div className="checkout__page--inner d-flex text-left">
                <div className="main checkout__mian">
                    <header className="main__header checkout__mian--header mb-30">
                        {/* <a className="logo logo__left mb-20" href="/"><img src="assets/img/logo/nav-log.png" alt="logo" /></a> */}
                        <details className="order__summary--mobile__version">
                            <summary className="order__summary--toggle border-radius-5">
                                <span className="order__summary--toggle__inner">
                                    <span className="order__summary--toggle__icon">
                                        <svg width="20" height="19" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.178 13.088H5.453c-.454 0-.91-.364-.91-.818L3.727 1.818H0V0h4.544c.455 0 .91.364.91.818l.09 1.272h13.45c.274 0 .547.09.73.364.18.182.27.454.18.727l-1.817 9.18c-.09.455-.455.728-.91.728zM6.27 11.27h10.09l1.454-7.362H5.634l.637 7.362zm.092 7.715c1.004 0 1.818-.813 1.818-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817zm9.18 0c1.004 0 1.817-.813 1.817-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span className="order__summary--toggle__text show">
                                        <span>Show order summary</span>
                                        <svg width="11" height="6" xmlns="http://www.w3.org/2000/svg" className="order-summary-toggle__dropdown" fill="currentColor"><path d="M.504 1.813l4.358 3.845.496.438.496-.438 4.642-4.096L9.504.438 4.862 4.534h.992L1.496.69.504 1.812z"></path></svg>
                                    </span>
                                    <span className="order__summary--final__price">$227.70</span>
                                </span>
                            </summary>
                            <div className="order__summary--section">
                                <div className="cart__table checkout__product--table">
                                    <table className="summary__table">
                                        <tbody className="summary__table--body">
                                            <tr className=" summary__table--items">
                                                <td className=" summary__table--list">
                                                    <div className="product__image two  d-flex align-items-center">
                                                        <div className="product__thumbnail border-radius-5">
                                                            <a href="/product"><img className="border-radius-5" src="assets/img/product/small-product7.png" alt="cart-product" /></a>
                                                            <span className="product__thumbnail--quantity">1</span>
                                                        </div>
                                                        <div className="product__description">
                                                            <h3 className="product__description--name h4"><a href="/product">Fresh-whole-fish2</a></h3>
                                                            <span className="product__description--variant">COLOR: Blue</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=" summary__table--list">
                                                    <span className="cart__price">£65.00</span>
                                                </td>
                                            </tr>
                                            <tr className="summary__table--items">
                                                <td className=" summary__table--list">
                                                    <div className="cart__product d-flex align-items-center">
                                                        <div className="product__thumbnail border-radius-5">
                                                            <a href="/product"><img className="border-radius-5" src="assets/img/product/small-product2.png" alt="cart-product" /></a>
                                                            <span className="product__thumbnail--quantity">1</span>
                                                        </div>
                                                        <div className="product__description">
                                                            <h3 className="product__description--name h4"><a href="/product">Vegetable-healthy</a></h3>
                                                            <span className="product__description--variant">COLOR: Green</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=" summary__table--list">
                                                    <span className="cart__price">£82.00</span>
                                                </td>
                                            </tr>
                                            <tr className=" summary__table--items">
                                                <td className=" summary__table--list">
                                                    <div className="cart__product d-flex align-items-center">
                                                        <div className="product__thumbnail border-radius-5">
                                                            <a href="/product"><img className="border-radius-5" src="assets/img/product/small-product4.png" alt="cart-product" /></a>
                                                            <span className="product__thumbnail--quantity">1</span>
                                                        </div>
                                                        <div className="product__description">
                                                            <h3 className="product__description--name h4"><a href="/product">Raw-onions-surface</a></h3>
                                                            <span className="product__description--variant">COLOR: White</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className=" summary__table--list">
                                                    <span className="cart__price">£78.00</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                                <div className="checkout__discount--code">
                                    <form className="d-flex" action="#">
                                        <label>
                                            <input className="checkout__discount--code__input--field border-radius-5" placeholder="Gift card or discount code"  type="text" />
                                        </label>
                                        <button className="checkout__discount--code__btn btn border-radius-5" type="submit">Apply</button>
                                    </form>
                                </div>
                                <div className="checkout__total">
                                    <table className="checkout__total--table">
                                        <tbody className="checkout__total--body">
                                            <tr className="checkout__total--items">
                                                <td className="checkout__total--title text-left">Subtotal </td>
                                                <td className="checkout__total--amount text-right">$860.00</td>
                                            </tr>
                                            <tr className="checkout__total--items">
                                                <td className="checkout__total--title text-left">Shipping</td>
                                                <td className="checkout__total--calculated__text text-right">Calculated at next step</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="checkout__total--footer">
                                            <tr className="checkout__total--footer__items">
                                                <td className="checkout__total--footer__title checkout__total--footer__list text-left">Total </td>
                                                <td className="checkout__total--footer__amount checkout__total--footer__list text-right">$860.00</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </details>
                        <nav>
                            <ol className="breadcrumb checkout__breadcrumb d-flex">
                                <li className="breadcrumb__item breadcrumb__item--completed d-flex align-items-center">
                                    <a className="breadcrumb__link" href="/cart">Cart</a>
                                    <svg className="readcrumb__chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17.007" height="16.831" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
                                </li>
                                <li className="breadcrumb__item breadcrumb__item--current d-flex align-items-center">
                                    <a className="breadcrumb__link" href="/cart">Information</a>
                                    <svg className="readcrumb__chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17.007" height="16.831" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
                                </li>
                                <li className="breadcrumb__item breadcrumb__item--blank d-flex align-items-center">
                                    <a className="breadcrumb__link" href="/cart">Shipping</a>
                                    <svg className="readcrumb__chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17.007" height="16.831" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
                                </li>
                                    <li className="breadcrumb__item breadcrumb__item--blank">
                                    <span className="breadcrumb__text current">Payment</span>
                                </li>
                            </ol>
                            </nav>
                    </header>
                    <main className="main__content_wrapper section--padding pt-0">
                     
                      
                            <div className="checkout__content--step checkout__contact--information2 border-radius-5" >
                                <div className="checkout__review d-flex justify-content-between align-items-center">
                                    <div className="checkout__review--inner d-flex align-items-center">
                                        <label className="checkout__review--label">Contact</label>
                                        <span className="checkout__review--content">{orderData && orderData.email ? orderData.email : 'No email provided'}</span>
                                    </div>
                                    {/* <div className="checkout__review--link">
                                        <button className="checkout__review--link__text" type="button">Change</button> 
                                    </div> */}
                                </div> 
                                <div className="checkout__review d-flex justify-content-between align-items-center">
                                    <div className="checkout__review--inner d-flex align-items-center">
                                        <label className="checkout__review--label"> Ship to</label>
                                        <span className="checkout__review--content">  {orderData && orderData.ship_add && orderData.ship_city && orderData.ship_country 
            ? `${orderData.ship_add}, ${orderData.ship_city}, ${orderData.ship_country}` 
            : 'No address provided'}</span>
                                    </div>
                                    {/* <div className="checkout__review--link">
                                        <button className="checkout__review--link__text" type="button">Change</button>    
                                    </div> */}
                                </div>
                                <div className="checkout__review d-flex justify-content-between align-items-center">
                                    {/* <div className="checkout__review--inner d-flex align-items-center">
                                        <label className="checkout__review--label"> Method</label>
                                        <span className="checkout__review--content"> Standard . <strong>${orderData && orderData.total_price}</strong></span>
                                    </div> */}
                                    {/* <div className="checkout__review--link">
                                        <button className="checkout__review--link__text" type="button">Change</button>    
                                    </div> */}
                                </div>
                            </div>
                           
                            <div className="checkout__content--step section__shipping--address">
                                <div className="section__header mb-25">
                                    <h2 className="section__header--title h3">Payment</h2>
                                    <p className="section__header--desc">All transactions are secure and encrypted.</p>
                                </div>
                                <div className="checkout__content--step__inner3 border-radius-5">
                                    <div className="checkout__address--content__header d-flex align-items-center justify-content-between">
                                        <span className="checkout__address--content__title">Credit card</span>
                                        <span className="heckout__address--content__icon"><img src="assets/img/icon/credit-card.svg" alt="card" /></span>
                                    </div>
                                    <div className="checkout__content--input__box--wrapper ">
                                         <div className="row">
                                            <div className="col-12 mb-12">
                                                <div className="checkout__input--list position__relative">
                                                    <label>
                                                        <input className="checkout__input--field border-radius-5" placeholder="Card number"  type="text" />
                                                    </label>
                                                    <button className="checkout__input--field__button" aria-label="search button" type="button">
                                                        
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path d="M336 208v-95a80 80 0 00-160 0v95" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/><rect x="96" y="208" width="320" height="272" rx="48" ry="48" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-12">
                                                <div className="checkout__input--list">
                                                    <label>
                                                        <input className="checkout__input--field border-radius-5" placeholder="Name on card"  type="text" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-12">
                                                <div className="checkout__input--list">
                                                    <label>
                                                        <input className="checkout__input--field border-radius-5" placeholder="piration date (MM / YY)"  type="text" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-12">
                                                <div className="checkout__input--list position__relative">
                                                    <label>
                                                        <input className="checkout__input--field border-radius-5" placeholder="Security code"  type="text" />
                                                    </label>
                                                    <button className="checkout__input--field__button" type="button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.51" height="18.443" viewBox="0 0 512 512"><title>Help Circle</title><path d="M256 80a176 176 0 10176 176A176 176 0 00256 80z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/><path d="M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296" fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="28"/><circle cx="250" cy="348" r="20"/></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> 
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <div className="checkout__content--step section__shipping--address pt-10">
                                <div className="section__header mb-25">
                                    <h2 className="section__header--title h3">Billing address</h2>
                                    <p className="section__header--desc">Select the address that matches your card or payment method.</p>
                                </div>
                                <div className="checkout__content--step__inner3 border-radius-5">
                                    <div className="checkout__address--content__header">
                                        <div className="shipping__contact--box__list">
                                            <div className="shipping__radio--input">
                                                <input className="shipping__radio--input__field" id="radiobox" name="checkmethod" type="radio" />
                                            </div>
                                            <label className="shipping__radio--label" htmlFor="radiobox">
                                                <span className="shipping__radio--label__primary">Same as shipping address</span>
                                            </label>
                                        </div>
                                        <div className="shipping__contact--box__list">
                                            <div className="shipping__radio--input">
                                                <input className="shipping__radio--input__field" id="radiobox2" name="checkmethod" type="radio" />
                                            </div>
                                            <label className="shipping__radio--label" htmlFor="radiobox2">
                                                <span className="shipping__radio--label__primary">Use a different billing address</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="checkout__content--input__box--wrapper ">
                
                                    </div>
                                </div>
                            </div> */}
                            <div className="checkout__content--step__footer d-flex align-items-center">
                           
                                 <a className="continue__shipping--btn btn border-radius-5" href="/checkout-3">Pay now</a> 
                                <a className="previous__link--content" href="/cart">Return to shipping</a>
                            </div>
                           
                    </main>
                    <footer className="main__footer main__footer--wrapper">
                        <p className="copyright__content">Copyright © 2022 <a className="copyright__content--link text__primary" href="/">Grocee</a> . All Rights Reserved.Design By Grocee</p>
                    </footer>
                </div>
                
            </div>
        </div>
    </div>
    {/* <!-- End checkout page area --> */}
    </>
  );
};

export default Checkout2;