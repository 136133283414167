


import React from 'react';
import  { useState, useEffect  } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreeTerms: false,
    });

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const [error, setError] = useState('');
    const navigate = useNavigate(); // Use useNavigate hook

    // Check for token and redirect if already logged in
    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            window.location.href = '/my-account';
        }
    }, [navigate]);

    // Handle change for registration form
    const handleRegisterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Handle change for login form
    const handleLoginChange = (e) => {
        const { name, value } = e.target;
        setLoginData({
            ...loginData,
            [name]: value,
        });
    };

    // Handle registration submission
    const handleRegisterSubmit = async (e) => {
        e.preventDefault();

        // Basic validation for registration
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (!formData.agreeTerms) {
            setError('You must agree to the terms and conditions');
            return;
        }

        setError('');

        try {
            // Send data to Laravel API for registration
            const response = await axios.post('https://freshbitegrocers.com/admin/public/api/admin/register', {
                name: formData.name,
                email: formData.email,
                password: formData.password,
                role: 0, // Role is set to 0
            });

            if (response.status === 201) {
                alert('User registered successfully!');
                // Optionally, redirect to login or another page after successful registration
            }
        } catch (error) {
            console.error('There was an error registering the user:', error);
            setError('An error occurred. Please try again.');
        }
    };

    // Handle login submission
    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://freshbitegrocers.com/admin/public/api/admin/login', {
                email: loginData.email,
                password: loginData.password,
            });

            if (response.status === 200) {
                // Store the token in local storage
                const { access_token } = response.data;
                localStorage.setItem('auth_token', access_token);

                // Redirect to the account page on successful login
                window.location.href = '/my-account';
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                setError('Invalid credentials');
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

  return (
    <>
    <main className="main__content_wrapper">
        
        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Login Page</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Account Page</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}

        {/* <!-- Start login section  --> */}
        <div className="login__section section--padding mb-80">
            <div className="container">
                    <div className="login__section--inner">
                        <div className="row row-cols-md-2 row-cols-1 text-left">
                            <div className="col">
                                <div className="account__login">
                                    <div className="account__login--header mb-25">
                                        <h2 className="account__login--header__title h3 mb-10">Login</h2>
                                        <p className="account__login--header__desc">Login if you area a returning customer.</p>
                                    </div>
                                    <div className="account__login--inner">
                                    <form onSubmit={handleLoginSubmit} className="account__login--inner">
                <h3>Login</h3>
                <label>
                    <input
                        className="account__login--input"
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        value={loginData.email}
                        onChange={handleLoginChange}
                        required
                    />
                </label>
                <label>
                    <input
                        className="account__login--input"
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={loginData.password}
                        onChange={handleLoginChange}
                        required
                    />
                </label>
                <div className="account__login--remember__forgot mb-15 d-flex justify-content-between align-items-center">
                    <div className="account__login--remember position__relative">
                        <input
                            className="checkout__checkbox--input"
                            id="check1"
                            type="checkbox"
                        />
                        <span className="checkout__checkbox--checkmark"></span>
                        <label
                            className="checkout__checkbox--label login__remember--label"
                            htmlFor="check1"
                        >
                            Remember me
                        </label>
                    </div>
                    {/* <button className="account__login--forgot" type="button">
                        Forgot Your Password?
                    </button> */}
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button className="account__login--btn btn" type="submit">
                    Login
                </button>
            </form>
                                        {/* <div className="account__login--divide">
                                            <span className="account__login--divide__text">OR</span>
                                        </div>
                                        <div className="account__social d-flex justify-content-center mb-15">
                                            <a className="account__social--link facebook" target="_blank" href="https://www.facebook.com">Facebook</a>
                                            <a className="account__social--link google" target="_blank" href="https://www.google.com">Google</a>
                                            <a className="account__social--link twitter" target="_blank" href="https://twitter.com">Twitter</a>
                                        </div> */}
                                        <p className="account__login--signup__text">Don,t Have an Account? <button type="submit">Sign up now</button></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="account__login register">
                                    <div className="account__login--header mb-25">
                                        <h2 className="account__login--header__title h3 mb-10">Create an Account</h2>
                                        <p className="account__login--header__desc">Register here if you are a new customer</p>
                                    </div>
                                    <div className="account__login--inner">
                                    <form onSubmit={handleRegisterSubmit} className="account__login--inner">
                <label>
                    <input
                        className="account__login--input"
                        placeholder="name"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleRegisterChange}
                        required
                    />
                </label>
                <label>
                    <input
                        className="account__login--input"
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleRegisterChange}
                        required
                    />
                </label>
                <label>
                    <input
                        className="account__login--input"
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleRegisterChange}
                        required
                    />
                </label>
                <label>
                    <input
                        className="account__login--input"
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleRegisterChange}
                        required
                    />
                </label>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button className="account__login--btn btn mb-10" type="submit">
                    Submit & Register
                </button>
                <div className="account__login--remember position__relative">
                    <input
                        className="checkout__checkbox--input"
                        id="check2"
                        type="checkbox"
                        name="agreeTerms"
                        checked={formData.agreeTerms}
                        onChange={handleRegisterChange}
                        required
                    />
                    <span className="checkout__checkbox--checkmark"></span>
                    <label className="checkout__checkbox--label login__remember--label" htmlFor="check2">
                        I have read and agree to the terms & conditions
                    </label>
                </div>
            </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>     
        </div>
        {/* <!-- End login section  --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}

    </main>    
    </>
);
};

export default Login;