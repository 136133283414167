import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Checkout = ()  => {

    

    const [email, setEmail] = useState('');
    const [shipFirstName, setShipFirstName] = useState('');
    const [shipLastName, setShipLastName] = useState('');
    const [shipCompany, setShipCompany] = useState('');
    const [shipAdd, setShipAdd] = useState('');
    const [shipApp, setShipApp] = useState('');
    const [shipCity, setShipCity] = useState('');
    const [shipCountry, setShipCountry] = useState('India');
    const [shipPostCode, setShipPostCode] = useState('');
    const [billFirstName, setBillFirstName] = useState('');
    const [billLastName, setBillLastName] = useState('');
    const [billAdd, setBillAdd] = useState('');
    const [billApp, setBillApp] = useState('');
    const [billCity, setBillCity] = useState('');
    const [billCountry, setBillCountry] = useState('India');
    const [billPostCode, setBillPostCode] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [couponId, setCouponId] = useState(null); // State to store coupon ID
    const [discount, setDiscount] = useState(0);
    const [finalTotal, setFinalTotal] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [message, setMessage] = useState('');
    const [placingOrder, setPlacingOrder] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            // Redirect to login if token is not present
            window.location.href = '/login';
        } else {
            // Simulate an API call or token validation if needed
            setLoading(false);
        }

    // Function to fetch cart data
    /*const fetchCartData = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                setError('User not authenticated.');
                return;
            }
    
            const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/cart', {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if (response.status === 200) {
                console.log('Cart Data:', response.data); // Log the entire response
    
                const cartItems = response.data.cart; // Access cart items directly
                if (cartItems && cartItems.length > 0) {
                    setCartItems(cartItems); // Set cartItems state with the received data
                    setError(''); // Clear any previous error messages
                } else {
                    setCartItems([]); // Set to an empty array if no items
                    setError('No cart items found.');
                }
            } else {
                setError('Failed to fetch cart data.');
            }
        } catch (error) {
            setCartItems([]); // Set to an empty array on error
            setError('Error fetching cart data.');
            console.error('Fetch Error:', error);
        }
    };*/
    const fetchCartData = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                setError('User not authenticated.');
                return;
            }

            const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/cart', {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const items = response.data.items;
                if (items && items.length > 0) {
                    // Fetch product details for each item
                    const products = await Promise.all(items.map(item => fetchProductDetails(item.product_id)));
                    // Combine cart items with product details
                    const updatedCartItems = items.map((item, index) => ({
                        ...item,
                        ...products[index]
                    }));
                    setCartItems(updatedCartItems); // Set cartItems state with the combined data
                    setError(''); // Clear any previous error messages
                } else {
                    setError('No cart items found.');
                }
            } else {
                setError('Failed to fetch cart data.');
            }
        } catch (error) {
            setError('Error fetching cart data.');
            console.error('Fetch Error:', error);
        }
    };

        // Function to fetch product details by ID
    const fetchProductDetails = async (productId) => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get(`https://freshbitegrocers.com/admin/public/api/admin/product/${productId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const product = response.data;
                return {
                    product_name: product.name,
                    product_image: product.image
                };
            } else {
                throw new Error('Failed to fetch product details.');
            }
        } catch (error) {
            console.error('Product Fetch Error:', error);
            return {}; // Return empty object in case of error
        }
    };

    // Fetch cart data when the component mounts
    fetchCartData();
    }, [navigate]);
    

   // Calculate totals from cart items
  const calculateTotals = () => {
    let subtotal = 0;

    cartItems.forEach(item => {
      const itemTotal = parseFloat(item.price) * item.quantity;
      subtotal += itemTotal;
    });

    const grandTotal = subtotal;

    return {
      subtotal: subtotal.toFixed(2),
      grandTotal: grandTotal.toFixed(2)
    };
  };

  // Get totals
  const { subtotal, grandTotal } = calculateTotals();

  // Update finalTotal based on coupon
  useEffect(() => {
    setFinalTotal(Number(grandTotal) - discount);
  }, [grandTotal, discount]);

 //coupon apply
  /*const handleApplyCoupon = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      
      // Fetch all coupons from the API
      const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/coupon');
      const coupons = response.data;
  
      const trimmedCouponCode = couponCode.trim().toLowerCase();
      const coupon = coupons.find(c => c.code.trim().toLowerCase() === trimmedCouponCode);
  
      if (coupon) {
        if (coupon.status === 0) {
          // Check if the coupon has already been used by the user
          const checkCouponUsageResponse = await axios.get(
            `https://freshbitegrocers.com/admin/public/api/admin/coupon/usage/${coupon.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          const { hasUsed } = checkCouponUsageResponse.data;
  
          if (hasUsed) {
            setMessage('You have already used this coupon');
            return;
          }
  
          let newDiscount = 0;
          if (coupon.type === 'percentage') {
            newDiscount = (Number(subtotal) * parseFloat(coupon.value)) / 100;
          } else if (coupon.type === 'fixed') {
            newDiscount = parseFloat(coupon.value);
          }
  
          newDiscount = Math.min(newDiscount, Number(subtotal));
  
          setDiscount(newDiscount);
          setFinalTotal(Number(grandTotal) - newDiscount);
          setCouponId(coupon.id); // Store the coupon ID
          setMessage('Coupon applied successfully');
        } else {
          setMessage('Coupon code is expired or inactive');
        }
      } else {
        setMessage('Invalid coupon code');
      }
    } catch (error) {
      console.error('Error applying coupon:', error.response ? error.response.data : error.message);
      setMessage('Failed to apply coupon');
    }
  };*/
  const handleApplyCoupon = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      
      // Fetch all coupons from the API
      const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/coupon');
      const coupons = response.data;
  
      const trimmedCouponCode = couponCode.trim().toLowerCase();
      const coupon = coupons.find(c => c.code.trim().toLowerCase() === trimmedCouponCode);
  
      if (coupon) {
        // Check if the coupon is expired
        const expirationDate = new Date(coupon.expire_date);
        const currentDate = new Date();
  
        if (currentDate > expirationDate) {
          const diffInDays = Math.ceil((currentDate - expirationDate) / (1000 * 60 * 60 * 24));
          
          let message = 'Coupon expired ';
          if (diffInDays === 1) {
            message += 'yesterday';
          } else if (diffInDays < 30) {
            message += `${diffInDays} days ago`;
          } else {
            const diffInMonths = Math.floor(diffInDays / 30);
            message += `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
          }
  
          setMessage(message);
          return;
        }
  
        if (coupon.status === 0) {
          // Check if the coupon has already been used by the user
          const checkCouponUsageResponse = await axios.get(
            `https://freshbitegrocers.com/admin/public/api/admin/coupon/usage/${coupon.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          const { hasUsed } = checkCouponUsageResponse.data;
  
          if (hasUsed) {
            setMessage('You have already used this coupon');
            return;
          }
  
          let newDiscount = 0;
          if (coupon.type === 'percentage') {
            newDiscount = (Number(subtotal) * parseFloat(coupon.value)) / 100;
          } else if (coupon.type === 'fixed') {
            newDiscount = parseFloat(coupon.value);
          }
  
          newDiscount = Math.min(newDiscount, Number(subtotal));
  
          setDiscount(newDiscount);
          setFinalTotal(Number(grandTotal) - newDiscount);
          setCouponId(coupon.id); // Store the coupon ID
          setMessage('Coupon applied successfully');
        } else {
          setMessage('Coupon code is inactive');
        }
      } else {
        setMessage('Invalid coupon code');
      }
    } catch (error) {
      console.error('Error applying coupon:', error.response ? error.response.data : error.message);
      setMessage('Failed to apply coupon');
    }
  };
  

  
  
  

    //form submit 
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const orderData = {
          email: email,
          ship_first_name: shipFirstName,
          ship_last_name: shipLastName,
          ship_company: shipCompany,
          ship_add: shipAdd,
          ship_app: shipApp,
          ship_city: shipCity,
          ship_country: shipCountry,
          ship_post_code: shipPostCode,
          bill_first_name: billFirstName,
          bill_last_name: billLastName,
          bill_add: billAdd,
          bill_app: billApp,
          bill_city: billCity,
          bill_country: billCountry,
          bill_post_code: billPostCode,
          product_status: 'pending',
          coupon_id: couponId, // Include the coupon ID in the order data
        };
    
        setPlacingOrder(true);
    
        try {
          const token = localStorage.getItem('auth_token');
          const response = await axios.post('https://freshbitegrocers.com/admin/public/api/admin/order/place', orderData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log('Order placed successfully:', response.data);
          setPlacingOrder(false);
          window.location.href = '/checkout-2';
        } catch (error) {
          console.error('Error placing order:', error);
          setPlacingOrder(false);
        }
      };


  return (
    <>
    {/* <!-- Start checkout page area --> */}
    <div className="checkout__page--area">
        <div className="container">
            <div className="checkout__page--inner d-flex text-left">
                <div className="main checkout__mian">
                    <header className="main__header checkout__mian--header mb-30">
                        {/* <h1 className="main__logo--title"><a className="logo logo__left mb-20" href="/"><img src="assets/img/logo/nav-log.png" alt="logo" /></a></h1> */}
                        <details className="order__summary--mobile__version">
                            <summary className="order__summary--toggle border-radius-5">
                                <span className="order__summary--toggle__inner">
                                    <span className="order__summary--toggle__icon">
                                        <svg width="20" height="19" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.178 13.088H5.453c-.454 0-.91-.364-.91-.818L3.727 1.818H0V0h4.544c.455 0 .91.364.91.818l.09 1.272h13.45c.274 0 .547.09.73.364.18.182.27.454.18.727l-1.817 9.18c-.09.455-.455.728-.91.728zM6.27 11.27h10.09l1.454-7.362H5.634l.637 7.362zm.092 7.715c1.004 0 1.818-.813 1.818-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817zm9.18 0c1.004 0 1.817-.813 1.817-1.817s-.814-1.818-1.818-1.818-1.818.814-1.818 1.818.814 1.817 1.818 1.817z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span className="order__summary--toggle__text show">
                                        <span>Show order summary</span>
                                        <svg width="11" height="6" xmlns="http://www.w3.org/2000/svg" className="order-summary-toggle__dropdown" fill="currentColor"><path d="M.504 1.813l4.358 3.845.496.438.496-.438 4.642-4.096L9.504.438 4.862 4.534h.992L1.496.69.504 1.812z"></path></svg>
                                    </span>
                                    <span className="order__summary--final__price">$227.70</span>
                                </span>
                            </summary>
                            <div className="order__summary--section">
                                <div className="cart__table checkout__product--table">
                                    <table className="summary__table">
                                    
                                    <tbody className="cart__table--body">
   {cartItems.map((item) => (
                                <tr className="cart__table--body__items" key={item.id}>
                                    <td className="cart__table--body__list">
                                        <div className="product__image two  d-flex align-items-center">
                                            <div className="product__thumbnail border-radius-5">
                                            <a href={`/product/${item.product.id}`}>
                                <img className="border-radius-5" src={`https://freshbitegrocers.com/admin/public/images/${item.product.image}`} alt="cart-product" />
                            </a>
                                                <span className="product__thumbnail--quantity">{item.quantity}</span>
                                            </div>
                                            <div className="product__description">
                                            <h3 className="product__description--name h4">
                                <a href={`/product/${item.product.id}`}>{item.product.name}</a>
                            </h3>
                                                {/* <h3 className="product__description--name h4"><a href="/product">Fresh-whole-fish</a></h3> */}
                                                {Array.isArray(item.variant_attributes) && item.variant_attributes.length > 0 ? (
    item.variant_attributes.map((attr) => (
        <span key={attr.attributeId} className="cart__content--variant">
            {attr.attributeName}
        </span>
    ))
) : (
    <span className="cart__content--variant">No attributes found</span>
)}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="cart__table--body__list">
                                    <span className="cart__price">₹{(parseFloat(item.price) * item.quantity).toFixed(2)}</span>
                                        {/* <span className="cart__price">£65.00</span> */}
                                    </td>
                                </tr>   
    ))}                        
                            </tbody>
                                    </table> 
                                </div>
                                <div className="checkout__discount--code">
                                    <form className="d-flex" action="#">
                                        <label>
                                            <input className="checkout__discount--code__input--field border-radius-5" placeholder="Enter your coupon code if you have one."  type="text" value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)} />
                                        </label>
                                        <button className="checkout__discount--code__btn btn border-radius-5" type="submit" onClick={handleApplyCoupon}>Apply</button>
                                    </form>
                                </div>
                                <div className="checkout__total">
                                    <table className="checkout__total--table">
                                        <tbody className="checkout__total--body">
                                            <tr className="checkout__total--items">
                                                <td className="checkout__total--title text-left">Subtotal </td>
                                                <td className="checkout__total--amount text-right">${subtotal}</td>
                                            </tr>
                                            <tr className="checkout__total--items">
                                                <td className="checkout__total--title text-left">Shipping</td>
                                                <td className="checkout__total--calculated__text text-right">Calculated at next step</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="checkout__total--footer">
                                            <tr className="checkout__total--footer__items">
                                                <td className="checkout__total--footer__title checkout__total--footer__list text-left">Total </td>
                                                <td className="checkout__total--footer__amount checkout__total--footer__list text-right">${grandTotal}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </details>
                        <nav>
                            <ol className="breadcrumb checkout__breadcrumb d-flex">
                                <li className="breadcrumb__item breadcrumb__item--completed d-flex align-items-center">
                                    <a className="breadcrumb__link" href="/cart">Cart</a>
                                    <svg className="readcrumb__chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17.007" height="16.831" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
                                </li>
                        
                                <li className="breadcrumb__item breadcrumb__item--current d-flex align-items-center">
                                    <span className="breadcrumb__text current">Information</span>
                                    <svg className="readcrumb__chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17.007" height="16.831" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
                                </li>
                                <li className="breadcrumb__item breadcrumb__item--blank d-flex align-items-center">
                                    <span className="breadcrumb__text">Shipping</span>
                                    <svg className="readcrumb__chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17.007" height="16.831" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
                                </li>
                                    <li className="breadcrumb__item breadcrumb__item--blank">
                                    <span className="breadcrumb__text">Payment</span>
                                </li>
                            </ol>
                        </nav>
                    </header>
                    <main className="main__content_wrapper section--padding pt-0">

                    <form onSubmit={handleSubmit}>
            <div className="checkout__content--step section__contact--information">
                <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-25">
                    <h2 className="section__header--title h3">Contact information</h2>
                    <p className="layout__flex--item">
                        Already have an account?
                        <a className="layout__flex--item__link" href="/login">Log in</a>  
                    </p>
                </div>
                <div className="customer__information">
                    <div className="checkout__email--phone mb-12">
                        <label>
                            <input
                                className="checkout__input--field border-radius-5"
                                placeholder="Email or mobile phone number"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="checkout__checkbox">
                        <input className="checkout__checkbox--input" id="check1" type="checkbox" />
                        <span className="checkout__checkbox--checkmark"></span>
                        <label className="checkout__checkbox--label" htmlFor="check1">
                            Email me with news and offers
                        </label>
                    </div>
                </div>
            </div>
            <div className="checkout__content--step section__shipping--address">
                <div className="section__header mb-25">
                    <h2 className="section__header--title h3">Shipping address</h2>
                </div>
                <div className="section__shipping--address__content">
                    <div className="row">
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list ">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="First name (optional)"
                                        type="text"
                                        value={shipFirstName}
                                        onChange={(e) => setShipFirstName(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Last name"
                                        type="text"
                                        value={shipLastName}
                                        onChange={(e) => setShipLastName(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Company (optional)"
                                        type="text"
                                        value={shipCompany}
                                        onChange={(e) => setShipCompany(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Address1"
                                        type="text"
                                        value={shipAdd}
                                        onChange={(e) => setShipAdd(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Apartment, suite, etc. (optional)"
                                        type="text"
                                        value={shipApp}
                                        onChange={(e) => setShipApp(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="City"
                                        type="text"
                                        value={shipCity}
                                        onChange={(e) => setShipCity(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list checkout__input--select select">
                                <label className="checkout__select--label" htmlFor="country">Country/region</label>
                                <select
                                    className="checkout__input--select__field border-radius-5"
                                    id="country"
                                    value={shipCountry}
                                    onChange={(e) => setShipCountry(e.target.value)}
                                >
                                    <option value="India">India</option>
                                    <option value="United States">United States</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Islands">Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Antigua Barbuda">Antigua Barbuda</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Postal code"
                                        type="text"
                                        value={shipPostCode}
                                        onChange={(e) => setShipPostCode(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="checkout__checkbox">
                        <input className="checkout__checkbox--input" id="check2" type="checkbox" />
                        <span className="checkout__checkbox--checkmark"></span>
                        <label className="checkout__checkbox--label" htmlFor="check2">
                            Save this information for next time
                        </label>
                    </div>
                </div>
            </div>
            <div className="checkout__content--step section__shipping--address">
                <div className="section__header mb-25">
                    <h2 className="section__header--title h3">Billing address</h2>
                </div>
                <div className="section__shipping--address__content">
                    <div className="row">
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list ">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="First name (optional)"
                                        type="text"
                                        value={billFirstName}
                                        onChange={(e) => setBillFirstName(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Last name"
                                        type="text"
                                        value={billLastName}
                                        onChange={(e) => setBillLastName(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Address1"
                                        type="text"
                                        value={billAdd}
                                        onChange={(e) => setBillAdd(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Apartment, suite, etc. (optional)"
                                        type="text"
                                        value={billApp}
                                        onChange={(e) => setBillApp(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="City"
                                        type="text"
                                        value={billCity}
                                        onChange={(e) => setBillCity(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list checkout__input--select select">
                                <label className="checkout__select--label" htmlFor="billCountry">Country/region</label>
                                <select
                                    className="checkout__input--select__field border-radius-5"
                                    id="billCountry"
                                    value={billCountry}
                                    onChange={(e) => setBillCountry(e.target.value)}
                                >
                                    <option value="India">India</option>
                                    <option value="United States">United States</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Islands">Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Antigua Barbuda">Antigua Barbuda</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-12">
                            <div className="checkout__input--list">
                                <label>
                                    <input
                                        className="checkout__input--field border-radius-5"
                                        placeholder="Postal code"
                                        type="text"
                                        value={billPostCode}
                                        onChange={(e) => setBillPostCode(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="checkout__checkbox">
                        <input className="checkout__checkbox--input" id="check2" type="checkbox" />
                        <span className="checkout__checkbox--checkmark"></span>
                        <label className="checkout__checkbox--label" htmlFor="check2">
                            Save this information for next time
                        </label>
                    </div>
                </div>
            </div>
            <button type="submit" className="continue__shipping--btn btn border-radius-5"  onClick={handleSubmit}>Place Order</button>
            <a className="previous__link--content" href="/cart">Return to cart</a>
        </form>
                      
                    </main>
                    <footer className="main__footer main__footer--wrapper">
                        <p className="copyright__content">Copyright © 2022 <a className="copyright__content--link text__primary" href="/">Grocee</a> . All Rights Reserved.Design By Grocee</p>
                    </footer>
                </div>
                <aside className="checkout__sidebar sidebar">
                    <div className="cart__table checkout__product--table">
                        <table className="cart__table--inner">
                        <tbody className="cart__table--body">
   {cartItems.map((item) => (
                                <tr className="cart__table--body__items" key={item.id}>
                                    <td className="cart__table--body__list">
                                        <div className="product__image two  d-flex align-items-center">
                                            <div className="product__thumbnail border-radius-5">
                                            <a href={`/product/${item.product.id}`}>
                                <img className="border-radius-5" src={`https://freshbitegrocers.com/admin/public/images/${item.product.image}`} alt="cart-product" />
                            </a>
                                                <span className="product__thumbnail--quantity">{item.quantity}</span>
                                            </div>
                                            <div className="product__description">
                                            <h3 className="product__description--name h4">
                                <a href={`/product/${item.product.id}`}>{item.product.name}</a>
                            </h3>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="cart__table--body__list">
                                    <span className="cart__price">₹{(parseFloat(item.price) * item.quantity).toFixed(2)}</span>
                                        {/* <span className="cart__price">£65.00</span> */}
                                    </td>
                                </tr>   
    ))}                        
                            </tbody>
                        </table> 
                    </div>
                    <div className="checkout__discount--code">
                    <form className="d-flex" action="#">
        <label>
          <input
            className="checkout__discount--code__input--field border-radius-5"
            placeholder="Enter your coupon code."
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </label>
        <button className="checkout__discount--code__btn btn border-radius-5" type="button" onClick={handleApplyCoupon}>
          Apply
        </button>
      </form>
      {message && <p className="coupon__message">{message}</p>}
     
                    </div>
                    <div className="checkout__total">
                        <table className="checkout__total--table">
                            <tbody className="checkout__total--body">
                                <tr className="checkout__total--items">
                                    <td className="checkout__total--title text-left">Subtotal </td>
                                    <td className="checkout__total--amount text-right">${subtotal}</td>
                                </tr>
                                <tr className="checkout__total--items">
                                    <td className="checkout__total--title text-left">Shipping</td>
                                    <td className="checkout__total--calculated__text text-right">₹{discount.toFixed(2)}</td>
                                </tr>
                            </tbody>
                            <tfoot className="checkout__total--footer">
                                <tr className="checkout__total--footer__items">
                                    <td className="checkout__total--footer__title checkout__total--footer__list text-left">Total </td>
                                    <td className="checkout__total--footer__amount checkout__total--footer__list text-right">${finalTotal.toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </aside>
            </div>
        </div>
    </div>
    {/* <!-- End checkout page area --> */}
    </>
  );
};

export default Checkout;