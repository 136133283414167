import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MyAccount2 = () => {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            // Redirect to login if token is not present
            window.location.href = '/login';
        } else {
            // Simulate an API call or token validation if needed
            setLoading(false);
        }

         //fetch order details
         const fetchOrders = async () => {
            try {
                const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/order', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('auth_token')}` // Add token if required
                    }
                });
                setOrders(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setLoading(false);
            }
        };

        fetchOrders();
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>; // Optional: Loading spinner or message
    }

    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('auth_token');

        // Redirect to the login page
        window.location.href = '/login';
    };

  return (
    <>
    <main className="main__content_wrapper">

        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">My Account</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">My Account</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}
        
        {/* <!-- my account section start --> */}
        <section className="my__account--section section--padding">
            <div className="container">
                <div className="my__account--section__inner border-radius-10 d-flex text-left">
                    <div className="account__left--sidebar">
                        <h2 className="account__content--title h3 mb-20">My Profile</h2>
                        <ul className="account__menu">
                            <li className="account__menu--list"><a href="/my-account">Dashboard</a></li>
                            {/* <li className="account__menu--list active"><a href="/my-account-2">Address</a></li>  */}
                            <li className="account__menu--list"><a href="/wishlist">Wishlist</a></li>
                            <li className="account__menu--list"><a href="/my-ticket">My Tickets</a></li>
                            <li className="account__menu--list"><a onClick={handleLogout}>Log Out</a></li>
                        </ul>
                    </div>
                    <div className="account__wrapper text-left">
                        <div className="account__content">
                            <h2 className="account__content--title h3 mb-20">Addresses</h2>
                            <button className="new__address--btn btn mb-25" type="button">Add a new address</button>
                            <div className="account__details two">
                                <h3 className="account__details--title h4">Default</h3>
                                <p className="account__details--desc">Admin <br /> Dhaka <br/> Dhaka 12119 <br/> Bangladesh</p>
                                <a className="account__details--link" href="/my-account-2">View Addresses (1)</a>
                            </div>
                            <div className="account__details--footer d-flex">
                                <button className="account__details--footer__btn" type="button">Edit</button>
                                <button className="account__details--footer__btn" type="button">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- my account section end --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}

    </main>
    </>
);
};

export default MyAccount2;
