import React from 'react';
import logo from './logo.svg';
import './App.css';
import Preloader from './pages/Preloader';
import Index from './pages/Index';
import Shop from './pages/Shop';
import Product from './pages/Product';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Checkout2 from './pages/Checkout2';
import Checkout3 from './pages/Checkout3';
import Checkout4 from './pages/Checkout4';
import Wishlist from './pages/Wishlist';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Faq from './pages/Faq';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Portfolio from './pages/Portfolio';
import Login from './pages/Login';
import MyAccount from './pages/MyAccount';
import MyAccount2 from './pages/MyAccount2';
import Compare from './pages/Compare';
import Help from './pages/Help';
import {BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import Nav from './pages/Nav';
import Footer from './pages/Footer';
import MyTicket from './pages/MyTicket';



function App() {
  return (
    <div className="App">
     <Preloader />
       <Router >
        <Nav />
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/shop' element={<Shop />} />
           <Route path='/shop/:id' element={<Shop/>}/> 
          <Route path='/product' element={<Product />} />
          <Route path='/product/:id' element={<Product/>}/>
          <Route path='/cart' element={<Cart />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/checkout-2' element={<Checkout2 />} />
          <Route path='/checkout-3' element={<Checkout3 />} />
          <Route path='/checkout-4' element={<Checkout4 />} />
          <Route path='/wishlist' element={<Wishlist />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/help' element={<Help />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog-details' element={<BlogDetails />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/login' element={<Login />} />
          <Route path='/my-account' element={<MyAccount />} />
          <Route path='/my-ticket' element={<MyTicket />} />
          <Route path='/my-account-2' element={<MyAccount2 />} />
          <Route path='/compare' element={<Compare />} />
        </Routes>
        <Footer /> 
      </Router>  
    </div>
  );
}

export default App;
